<template>
  <process-layout title="注文確認画面" step="confirm">
    <CartSummary :order-info="orderInfo" confirm-mode="cv-confirm"></CartSummary>
    <div class="text-center mb-10">
      <template v-if="timeout > 0">
        <div class="mb-10">
          <p>受付終了まで あと</p>
          <h2>
            <span v-if="remaining.hours">{{ remaining.hours }} 時間</span>
            <span v-if="remaining.minutes">{{ remaining.minutes }} 分</span>
            <span v-if="!remaining.minutes && remaining.hours">00 分</span>
            {{ remaining.seconds }} 秒
          </h2>
        </div>

        <div class="mb-3">
          <button class="process-confirm-btn" @click="submit">ご注文完了へ</button>
        </div>
        <div class="mb-2">
          <button class="mx-2 process-back-btn p-0" @click="back">入力内容を修正する</button>
        </div>
      </template>

      <template v-else>
        <p>有効期限を経過しました。</p>
        <p>受付を終了させていただきます。</p>
      </template>
    </div>
  </process-layout>
</template>

<script setup lang="ts">
import CartSummary from "@/components/organisms/pages/cart/CartSummary.vue";
import ProcessLayout from "@/components/organisms/pages/lp/ProcessLayout.vue";

import _ from "lodash";
import { computed, onBeforeMount, ref } from "vue";
import { useCartStore } from "@/stores/cart";
import { useOrderStore } from "@/stores/order";
import { PublicsOrdersPricesType } from "@/types/publics/orders/PricesType";

interface Props {
  orderNumber: string;
  baseUrl: string;
  mode: string;
}

const props = defineProps<Props>();

const cartStore = useCartStore();
const orderStore = useOrderStore();

const orderInfo = ref<PublicsOrdersPricesType | null>(null);

const timeout = ref(0);

const interval = ref<any>(null);

const BASE_COUNTDOWN = (new Date().getTimezoneOffset() / 60 + 9) * 3600;

const remaining = computed(() => ({
  hours: Math.floor(timeout.value / 3600),
  minutes: Math.floor(timeout.value / 60) % 60,
  seconds: timeout.value % 60,
}));

const products = computed(() => {
  if (cartStore.offerData[props.orderNumber]) {
    return cartStore.offerData[props.orderNumber].map((item: any) => {
      return {
        id: item.id,
        quantity: item.quantity,
      };
    });
  }

  return [];
});

const subsOrderData = computed(() => cartStore.offerData.subsOrderData);
const specifyDeliveryDate = computed(() => cartStore.offerData.specifyDeliveryDate);
const shippingCarrierId = computed(() => cartStore.offerData.shippingCarrierId);

onBeforeMount(() => {
  cartStore.lastPage = "cv_confirm";

  if (props.mode === "upsell") {
    validateOfferCvUpsell();
  } else if (props.mode === "xsell") {
    validateOfferCvXsell();
  }
});

function setTimeInterval(data: any) {
  orderInfo.value = _.omit(data, ["timeout"]) as any;
  timeout.value = BASE_COUNTDOWN + data.timeout!;
  interval.value = setInterval(() => {
    timeout.value -= 1;
    if (timeout.value <= 0) clearInterval(interval.value);
  }, 1000);
}

function redirectToLpPage() {
  window.location.href = "/lp?u=" + props.baseUrl;
}

function redirectToFinishPage() {
  window.location.href = "/lp/finish" + window.location.search;
}

function validateOfferCvUpsell() {
  const productId = cartStore.offerData[props.orderNumber];

  if (!productId) {
    redirectToLpPage();
    return;
  }
  cartStore
    .validateOfferCvUpsell(
      props.orderNumber,
      productId,
      subsOrderData.value,
      specifyDeliveryDate.value,
      shippingCarrierId.value,
    )
    .then((data) => setTimeInterval(data))
    .catch(() => redirectToLpPage());
}

function validateOfferCvXsell() {
  cartStore
    .validateOfferCvXsell(
      props.orderNumber,
      products.value,
      subsOrderData.value,
      specifyDeliveryDate.value,
      shippingCarrierId.value,
    )
    .then((data) => setTimeInterval(data))
    .catch(() => redirectToLpPage());
}

function submit() {
  if (props.mode === "upsell") {
    submitCvUpsell();
  } else if (props.mode === "xsell") {
    submitCvXsell();
  }
}

function submitCvUpsell() {
  const productId = cartStore.offerData[props.orderNumber];

  cartStore
    .submitOfferCvUpsell(
      props.orderNumber,
      productId,
      subsOrderData.value,
      specifyDeliveryDate.value,
      shippingCarrierId.value,
    )
    .then(() => {
      cartStore.$reset();
      orderStore.resetPageView(props.baseUrl);
      redirectToFinishPage();
    })
    .catch(() => redirectToFinishPage());
}

function submitCvXsell() {
  cartStore
    .submitOfferCvXsell(
      props.orderNumber,
      products.value,
      subsOrderData.value,
      specifyDeliveryDate.value,
      shippingCarrierId.value,
    )
    .then(() => {
      cartStore.$reset();
      orderStore.resetPageView(props.baseUrl);
      redirectToFinishPage();
    });
}

function back() {
  window.location.href = "/lp?u=" + props.baseUrl;
}
</script>
