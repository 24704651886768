<template>
  <b-card class="mb-3" v-show="mode == 'product' && deliveryCompanyDisplay">
    <template #header>配送業者</template>

    <FormValidator
      name="shipping_carrier.shipping_carrier_id"
      clientName="shippingCarrierId"
      label="配送業者"
      required
      float
    >
      <b-select
        text-field="name"
        value-field="id"
        v-model="shippingCarrierId"
        :options="shippingCarrierOptions"
        name="shipping_company"
        id="shipping_company"
      >
        <template #first>
          <option :value="null">選択してください</option>
        </template></b-select
      >
    </FormValidator>
  </b-card>
</template>

<script setup lang="ts">
import { computed, watch, onMounted } from "vue";

import { useCartStore } from "@/stores/cart";
import { useBaseInfoStore } from "@/stores/base_info";
import { useField } from "vee-validate";

interface Props {
  buyMultipleVariant: boolean;
  deliveryCompanyDisplay: boolean;
}

const props = defineProps<Props>();

const { value: shippingCarrierId } = useField("shippingCarrierId");
const { value: paymentMethodCode }: any = useField("paymentData.paymentMethodCode");
const { value: productsData }: any = useField("productsData");

const baseInfoStore = useBaseInfoStore();
const cartStore = useCartStore();

const fetchShippingCarrierOptions = cartStore.fetchShippingCarrierOptions;

const shippingCarrierOptions = computed(() => cartStore.shippingCarrierOptions);
const mode = computed(() => baseInfoStore.baseInfo?.shippingCarrierBasedOn ?? "");

const shippingCarrierVariables = computed(() => ({
  input: {
    paymentMethodCode: paymentMethodCode.value,
    productData: productsData.value,
  },
}));

onMounted(() => {
  fetchShippingCarrierOptions(shippingCarrierVariables.value);
  if (!shippingCarrierId.value) handleShippingCarrierOptionsChange();
});

watch(shippingCarrierOptions, handleShippingCarrierOptionsChange);

watch(paymentMethodCode, () => {
  if (!paymentMethodCode.value) return;
  if (mode.value == "payment_method") fetchShippingCarrierOptions(shippingCarrierVariables.value);
});

watch(
  productsData,
  (newValue) => {
    if (newValue[0] === null) return;
    if (mode.value == "product") fetchShippingCarrierOptions(shippingCarrierVariables.value);
  },
  { deep: true },
);

function handleShippingCarrierOptionsChange() {
  let id: any;

  const defaultShippingCarrier = shippingCarrierOptions.value.find(
    (item: any) => item.default == true,
  );
  const firstShippingCarrier = shippingCarrierOptions.value[0];
  if (defaultShippingCarrier) id = defaultShippingCarrier.id;
  else if (firstShippingCarrier) id = firstShippingCarrier.id;

  shippingCarrierId.value = id;
}
</script>
