<template>
  <div v-if="showSubsOrderData">
    <form-validator name="subs_order_data.payment_schedule" label="お届けサイクルの指定" required>
      <b-select :options="paymentScheduleOptions" v-model="paymentSchedule" />
    </form-validator>

    <div class="d-flex align-items-center mt-5 gap-5px">
      <form-validator
        name="subs_order_data.scheduled_to_be_delivered_every_x_month"
        v-if="!paymentScheduleIsTerm"
      >
        <b-select :options="everyXMonthOptions" v-model="scheduledToBeDeliveredEveryXMonth" />
      </form-validator>

      <span v-if="!paymentScheduleIsTerm">ごとの</span>

      <template v-if="paymentScheduleIsDate">
        <form-validator name="subs_order_data.scheduled_to_be_delivered_on_xth_day">
          <b-select :options="onXthDayOptions()" v-model="scheduledToBeDeliveredOnXthDay" />
        </form-validator>

        <span>に配送</span>
      </template>

      <template v-if="paymentScheduleIsTerm">
        <form-validator
          name="subs_order_data.scheduled_to_be_delivered_every_x_day"
          clientName="subsOrderData.scheduledToBeDeliveredEveryXDay"
          float
        >
          <b-select
            class="form-control"
            :options="everyXDayOptions"
            v-model="scheduledToBeDeliveredEveryXDay"
          />
        </form-validator>

        <span>ごとに配送</span>
      </template>

      <template v-if="paymentScheduleIsDayOfWeek">
        <form-validator name="subs_order_data.scheduled_to_be_delivered_on_xth_day_of_week">
          <b-select
            class="form-control"
            :options="range(1, 4)"
            v-model="scheduledToBeDeliveredOnXthDayOfWeek"
          />
        </form-validator>

        <span>回目の</span>

        <form-validator name="subs_order_data.scheduled_to_be_delivered_every_x_day_of_week">
          <b-select
            class="form-control"
            :options="dayOfWeekJpOptions"
            v-model="scheduledToBeDeliveredEveryXDayOfWeek"
          />
        </form-validator>

        <span>曜日に配送</span>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch } from "vue";
import { useCartStore } from "@/stores/cart";
import { useOrderStore } from "@/stores/order";

import { useField } from "vee-validate";

import { flatten, uniqWith, isEqual, cloneDeep, pick } from "lodash";

const cartStore = useCartStore();
const orderStore = useOrderStore();

const { value: paymentSchedule }: any = useField("subsOrderData.paymentSchedule");

// Payment schedule date
const { value: scheduledToBeDeliveredEveryXMonth }: any = useField(
  "subsOrderData.scheduledToBeDeliveredEveryXMonth",
);
const { value: scheduledToBeDeliveredOnXthDay }: any = useField(
  "subsOrderData.scheduledToBeDeliveredOnXthDay",
);

// Payment schedule term
const { value: scheduledToBeDeliveredEveryXDay }: any = useField(
  "subsOrderData.scheduledToBeDeliveredEveryXDay",
);

// Payment schedule day of week
const { value: scheduledToBeDeliveredEveryXDayOfWeek }: any = useField(
  "subsOrderData.scheduledToBeDeliveredEveryXDayOfWeek",
);
const { value: scheduledToBeDeliveredOnXthDayOfWeek }: any = useField(
  "subsOrderData.scheduledToBeDeliveredOnXthDayOfWeek",
);

const { value: billingPrefectureId } = useField("billingAddressData.prefectureId");
const { value: shippingPrefectureId } = useField("shippingAddressData.prefectureId");
const { value: addressSameWithBilling } = useField("shippingAddressData.sameWithBillingAddress");
const { value: specifyDeliveryDate }: any = useField("specifyDeliveryDate");
const { value: specifyDeliveredAt } = useField("specifyDeliveryDate.scheduledToBeDeliveredAt");
const { value: subsOrderData }: any = useField("subsOrderData");
var { value: scheduledToBeDeliveredAt } = useField("subsOrderData.scheduledToBeDeliveredAt");

const products = computed(() =>
  orderStore.productVariantInput.filter((item: any) => item.isRecurring && item.count > 0),
);

const paymentScheduleOptions = computed(() => orderStore.productInfo.paymentScheduleOptions ?? []);

const everyXMonthOptions = computed(() => {
  const options = products.value.map((item: any) => item.recurringOptions.dateOptions);

  return uniqWith(
    flatten(options).sort(function (a, b) {
      return a.value - b.value;
    }),
    isEqual,
  );
});

const everyXDayOptions = computed(() => {
  const options = products.value.map((item: any) => item.recurringOptions.termOptions);

  return uniqWith(
    flatten(options).sort(function (a, b) {
      return a.value - b.value;
    }),
    isEqual,
  );
});

const dayOfWeekJpOptions = computed(() => cartStore.dateOfWeekJp);

const showSubsOrderData = computed(
  () => products.value.length && paymentScheduleOptions.value.length,
);

const paymentScheduleIsDate = computed(() => paymentSchedule.value == "date");
const paymentScheduleIsTerm = computed(() => paymentSchedule.value == "term");
const paymentScheduleIsDayOfWeek = computed(() => paymentSchedule.value == "day_of_week");

// reset subs order data when products
watch(
  () => products.value.length,
  (newVal, _) => {
    if (newVal > 0) return;

    paymentSchedule.value = null;

    scheduledToBeDeliveredEveryXMonth.value = null;
    scheduledToBeDeliveredOnXthDay.value = null;

    scheduledToBeDeliveredEveryXDayOfWeek.value = null;
    scheduledToBeDeliveredOnXthDayOfWeek.value = null;

    scheduledToBeDeliveredEveryXDay.value = null;
  },
);

// when change payment schedule
watch(
  () => paymentSchedule.value,
  () => {
    changePaymentSchedule();
    defaultData();
  },
);

// when delete product sub in products
watch(
  () => products.value.length,
  (newVal, oldVal) => {
    if (newVal >= oldVal) return;

    switch (paymentSchedule.value) {
      case "date":
        if (
          everyXMonthOptions.value
            .map((item) => item.value)
            .includes(scheduledToBeDeliveredEveryXMonth.value)
        )
          return;

        scheduledToBeDeliveredEveryXMonth.value = null;
        break;
      case "term":
        if (
          everyXDayOptions.value
            .map((item) => item.value)
            .includes(scheduledToBeDeliveredEveryXDay.value)
        )
          return;

        scheduledToBeDeliveredEveryXDay.value = null;
        break;
      case "day_of_week":
        if (
          everyXMonthOptions.value
            .map((item) => item.value)
            .includes(scheduledToBeDeliveredEveryXMonth.value)
        )
          return;

        scheduledToBeDeliveredEveryXMonth.value = null;
        break;
    }
  },
);

// these data change when subsOrderData exist
watch(
  () => [
    scheduledToBeDeliveredEveryXMonth.value,
    scheduledToBeDeliveredOnXthDay.value,
    scheduledToBeDeliveredEveryXDay.value,
    scheduledToBeDeliveredEveryXDayOfWeek.value,
    scheduledToBeDeliveredOnXthDayOfWeek.value,
  ],
  () => {
    calculateSubsOrderEstimate();
  },
);

// these data need initial data when user don't see subsOrderData
watch(
  () => specifyDeliveredAt.value,
  () => calculateSubsOrderEstimate(products.value?.[0]),
);

// when products first change
watch(
  () => products.value?.[0],
  () => {
    defaultData();
    if (products.value?.[0] && !paymentSchedule.value)
      calculateSubsOrderEstimate(products.value[0]);
  },
);

const range = (start: number, stop: number) =>
  Array.from({ length: stop - start + 1 }, (_, i) => start + i);

function onXthDayOptions() {
  const array = range(1, 28).map((item: number) => {
    return {
      text: `${item}日`,
      value: item,
    };
  });

  array.push({ text: `末日`, value: 29 });

  return array;
}

function defaultData() {
  const product = products.value?.[0];

  if (product && product.isRecurring) {
    if (!paymentSchedule.value) {
      paymentSchedule.value = paymentScheduleOptions.value
        .map((item) => item.value)
        .includes(product.paymentSchedule)
        ? product.paymentSchedule
        : paymentScheduleOptions.value?.[0]?.value || null;
    }

    if (
      !scheduledToBeDeliveredEveryXMonth.value &&
      (paymentScheduleIsDate.value || paymentScheduleIsDayOfWeek.value)
    )
      scheduledToBeDeliveredEveryXMonth.value =
        product.scheduledToBeDeliveredEveryXMonth ||
        product.recurringOptions?.dateOptions?.[0]?.value ||
        paymentSchedule.value.dateOptions?.[0]?.value;

    if (!scheduledToBeDeliveredOnXthDay.value && paymentScheduleIsDate.value)
      scheduledToBeDeliveredOnXthDay.value = product.scheduledToBeDeliveredOnXthDay || 1;

    if (!scheduledToBeDeliveredEveryXDay.value && paymentScheduleIsTerm.value) {
      const options = everyXDayOptions.value?.map((item) => Number(item.value));

      scheduledToBeDeliveredEveryXDay.value = options?.includes(
        product.scheduledToBeDeliveredEveryXDay,
      )
        ? product.scheduledToBeDeliveredEveryXDay
        : product.recurringOptions?.termOptions?.[0]?.value ||
          paymentSchedule.value.termOptions?.[0]?.value ||
          null;
    }

    if (!scheduledToBeDeliveredEveryXDayOfWeek.value && paymentScheduleIsDayOfWeek.value)
      scheduledToBeDeliveredEveryXDayOfWeek.value =
        product.scheduledToBeDeliveredEveryXDayOfWeek || 1;

    if (!scheduledToBeDeliveredOnXthDayOfWeek.value && paymentScheduleIsDayOfWeek.value)
      scheduledToBeDeliveredOnXthDayOfWeek.value =
        product.scheduledToBeDeliveredOnXthDayOfWeek || 1;
  }
}

function changePaymentSchedule() {
  switch (paymentSchedule.value) {
    case "date":
      scheduledToBeDeliveredEveryXDay.value = null;

      scheduledToBeDeliveredEveryXDayOfWeek.value = null;
      scheduledToBeDeliveredOnXthDayOfWeek.value = null;
      break;
    case "term":
      scheduledToBeDeliveredEveryXMonth.value = null;
      scheduledToBeDeliveredOnXthDay.value = null;

      scheduledToBeDeliveredEveryXDayOfWeek.value = null;
      scheduledToBeDeliveredOnXthDayOfWeek.value = null;
      break;
    case "day_of_week":
      scheduledToBeDeliveredOnXthDay.value = null;

      scheduledToBeDeliveredEveryXDay.value = null;
      break;
  }
}

async function calculateSubsOrderEstimate(product?: any) {
  const prefectureId = addressSameWithBilling.value
    ? billingPrefectureId.value
    : shippingPrefectureId.value;

  let dataEstimate = null;

  if (product && product.isRecurring && !subsOrderData.value.paymentSchedule) {
    dataEstimate = pick(product, [
      "paymentSchedule",
      "scheduledToBeDeliveredEveryXMonth",
      "scheduledToBeDeliveredOnXthDay",
      "scheduledToBeDeliveredEveryXDay",
      "scheduledToBeDeliveredEveryXDayOfWeek",
      "scheduledToBeDeliveredOnXthDayOfWeek",
    ]);
  } else {
    dataEstimate = cloneDeep(subsOrderData.value);
  }

  if (dataEstimate.paymentSchedule) {
    scheduledToBeDeliveredAt.value = await cartStore.calculateSubsOrderEstimate({
      subsOrderData: dataEstimate,
      specifyDeliveryDateData: specifyDeliveryDate.value,
      prefectureId: prefectureId,
    });
  }
}
</script>

<style>
.gap-5px {
  gap: 5px;
}
</style>
