<template>
  <b-card class="mb-3">
    <template #header>お支払い情報</template>

    <div v-show="!isAddNewCard">
      <FormValidator
        class="mb-8"
        label="支払い方法"
        name="payment_data.payment_method_code"
        client-name="paymentData.paymentMethodCode"
        required
        float
      >
        <b-select
          v-model="paymentMethodCode"
          :options="options"
          name="payment_method"
          id="payment_method"
        >
          <template #first>
            <option value="">選択してください</option>
          </template></b-select
        >
      </FormValidator>

      <div class="overflow-hidden my-4">
        <div class="payment-method-description" v-html="contentDevice"></div>
      </div>
    </div>

    <card-list
      @addCard="isAddNewCard = true"
      v-show="cardAvailable && !isAddNewCard"
      name="payment_card_select"
      id="payment_card_select"
    />

    <b-row v-show="isAddNewCard">
      <b-col>
        <new-card-form :isAddNewCard="isAddNewCard" />
        <div
          class="mt-3"
          v-show="isAddNewCard"
          @click="isAddNewCard = false"
          name="payment_card_select_back"
          id="payment_card_select_back"
        >
          <undo-add-new-card />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script setup lang="ts">
import { useField } from "vee-validate";
import CardList from "@/components/organisms/pages/cart/cards/CardList.vue";

import { SharedGeneralsSelectOptionType } from "@/types/shared/generals/SharedGeneralsSelectOptionType";
import { PaymentMethodType } from "@/types/shared/payments/PaymentMethodType";
import { computed } from "vue";

import { CARD_REQUIRED_PAYMENT_METHOD_CODES } from "@/helpers/constants";
import NewCardForm from "@/components/organisms/pages/cart/cards/NewCardForm.vue";
import UndoAddNewCard from "@/components/organisms/pages/cart/cards/UndoAddNewCard.vue";

interface Props {
  options: SharedGeneralsSelectOptionType[];
  paymentMethods: PaymentMethodType[];
}

const props = defineProps<Props>();

const { value: paymentMethodCode } = useField("paymentData.paymentMethodCode");
const { value: isAddNewCard } = useField("paymentData.isAddNewCard");

const cardAvailable = computed(() => {
  return CARD_REQUIRED_PAYMENT_METHOD_CODES.includes(paymentMethodCode.value as string);
});

const contentDevice = computed(() => {
  const paymentMethod = props.paymentMethods.find((item) => item.code == paymentMethodCode.value);
  return paymentMethod ? paymentMethod.contentDevice : "";
});
</script>

<style lang="scss" scoped>
:deep(.payment-method-description) {
  img {
    max-width: 100%;
  }
}
</style>
