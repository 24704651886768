<template>
  <div class="mb-4" v-if="showSubsOrderData">
    <form-validator name="subs_order_data.payment_schedule" label="お届けサイクルの指定" required>
      <b-select
        :options="paymentScheduleOptions"
        v-model="paymentSchedule"
        name="shipping_cycle"
        id="shipping_cycle"
      />
    </form-validator>

    <div class="d-flex align-items-center mt-5 gap-10px flex-wrap gy-5">
      <form-validator
        class="col-6 col-md-4"
        name="subs_order_data.scheduled_to_be_delivered_every_x_month"
        v-if="!paymentScheduleIsTerm"
      >
        <b-select
          v-model="scheduledToBeDeliveredEveryXMonth"
          :options="everyXMonthOptions"
          :name="paymentScheduleIsDate ? 'shipping_cycle_date_month' : 'shipping_cycle_week_month'"
          :id="paymentScheduleIsDate ? 'shipping_cycle_date_month' : 'shipping_cycle_week_month'"
        />
      </form-validator>

      <span v-if="!paymentScheduleIsTerm">ごとの</span>

      <template v-if="paymentScheduleIsDate">
        <form-validator
          class="col-6 col-md-4"
          name="subs_order_data.scheduled_to_be_delivered_on_xth_day"
        >
          <b-select
            v-model="scheduledToBeDeliveredOnXthDay"
            :options="onXthDayOptions()"
            name="shipping_cycle_date_day"
            id="shipping_cycle_date_day"
          />
        </form-validator>

        <span>に配送</span>
      </template>

      <template v-if="paymentScheduleIsTerm">
        <form-validator
          class="col-6 col-md-4"
          name="subs_order_data.scheduled_to_be_delivered_every_x_day"
          clientName="subsOrderData.scheduledToBeDeliveredEveryXDay"
          float
        >
          <b-select
            v-model="scheduledToBeDeliveredEveryXDay"
            class="form-control"
            :options="everyXDayOptions"
            name="shipping_cycle_day"
            id="shipping_cycle_day"
          />
        </form-validator>

        <span>ごとに配送</span>
      </template>

      <template v-if="paymentScheduleIsDayOfWeek">
        <form-validator
          class="col-6 col-md-4"
          name="subs_order_data.scheduled_to_be_delivered_on_xth_day_of_week"
        >
          <b-select
            class="form-control"
            :options="range(1, 4)"
            v-model="scheduledToBeDeliveredOnXthDayOfWeek"
            name="shipping_cycle_week_time"
            id="shipping_cycle_week_time"
          />
        </form-validator>

        <span>回目の</span>

        <form-validator
          class="col-6 col-md-4"
          name="subs_order_data.scheduled_to_be_delivered_every_x_day_of_week"
        >
          <b-select
            class="form-control"
            :options="dayOfWeekJpOptions"
            v-model="scheduledToBeDeliveredEveryXDayOfWeek"
            name="shipping_cycle_week_day"
            id="shipping_cycle_week_day"
          />
        </form-validator>

        <span>曜日に配送</span>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch, ref } from "vue";
import { useCartStore } from "@/stores/cart";
import { useOrderStore } from "@/stores/order";

import { useField } from "vee-validate";

import { cloneDeep, maxBy, minBy, pick } from "lodash";

import { ProductType } from "@/types/ProductType";
import { ProductUrlType } from "@/types/ProductUrlType";

import "@/helpers/arrays.js";

interface Props {
  orderMode: string;
}

const props = defineProps<Props>();

const cartStore = useCartStore();
const orderStore = useOrderStore();

const urlInfo = computed(() => cartStore.urlInfo);

const { value: paymentSchedule }: any = useField("subsOrderData.paymentSchedule");

// Payment schedule date
const { value: scheduledToBeDeliveredEveryXMonth }: any = useField(
  "subsOrderData.scheduledToBeDeliveredEveryXMonth",
);
const { value: scheduledToBeDeliveredOnXthDay }: any = useField(
  "subsOrderData.scheduledToBeDeliveredOnXthDay",
);

// Payment schedule term
const { value: scheduledToBeDeliveredEveryXDay }: any = useField(
  "subsOrderData.scheduledToBeDeliveredEveryXDay",
);

// Payment schedule day of week
const { value: scheduledToBeDeliveredEveryXDayOfWeek }: any = useField(
  "subsOrderData.scheduledToBeDeliveredEveryXDayOfWeek",
);
const { value: scheduledToBeDeliveredOnXthDayOfWeek }: any = useField(
  "subsOrderData.scheduledToBeDeliveredOnXthDayOfWeek",
);

const { value: billingPrefectureId } = useField("billingAddressData.prefectureId");
const { value: shippingPrefectureId } = useField("shippingAddressData.prefectureId");
const { value: addressSameWithBilling } = useField("shippingAddressData.sameWithBillingAddress");
const { value: specifyDeliveryDate }: any = useField("specifyDeliveryDate");
const { value: specifyDeliveredAt } = useField("specifyDeliveryDate.scheduledToBeDeliveredAt");
const { value: subsOrderData }: any = useField("subsOrderData");
var { value: scheduledToBeDeliveredAt } = useField("subsOrderData.scheduledToBeDeliveredAt");

async function calculateSubsOrderEstimate(product?: any) {
  const prefectureId = addressSameWithBilling.value
    ? billingPrefectureId.value
    : shippingPrefectureId.value;

  let dataEstimate = null;

  if (product && product.isRecurring && !subsOrderData.value.paymentSchedule) {
    dataEstimate = pick(product, [
      "paymentSchedule",
      "scheduledToBeDeliveredEveryXMonth",
      "scheduledToBeDeliveredOnXthDay",
      "scheduledToBeDeliveredEveryXDay",
      "scheduledToBeDeliveredEveryXDayOfWeek",
      "scheduledToBeDeliveredOnXthDayOfWeek",
    ]);
  } else {
    dataEstimate = cloneDeep(subsOrderData.value);
  }

  if (dataEstimate?.paymentSchedule) {
    scheduledToBeDeliveredAt.value = await cartStore.calculateSubsOrderEstimate({
      subsOrderData: dataEstimate,
      specifyDeliveryDateData: specifyDeliveryDate.value,
      prefectureId: prefectureId,
    });
  }
}

// these data change when subsOrderData exist
watch(
  () => [
    scheduledToBeDeliveredEveryXMonth.value,
    scheduledToBeDeliveredOnXthDay.value,
    scheduledToBeDeliveredEveryXDay.value,
    scheduledToBeDeliveredEveryXDayOfWeek.value,
    scheduledToBeDeliveredOnXthDayOfWeek.value,
  ],
  () => {
    calculateSubsOrderEstimate();
  },
);

// these data need initial data when user don't see subsOrderData
watch(
  () => [
    specifyDeliveredAt.value,
    billingPrefectureId.value,
    shippingPrefectureId.value,
    addressSameWithBilling,
  ],
  () => calculateSubsOrderEstimate(productChosen.value),
);

const { value: productsData }: any = useField("productsData");

const recurringOptions = computed(() => urlInfo.value.recurringOptions ?? {});

const paymentScheduleOptions = computed(() => recurringOptions.value.paymentScheduleOptions ?? []);

const everyXMonthOptions = computed(() => {
  if (buyMultipleVariant.value) {
    return recurringOptions.value.dateOptions;
  } else {
    const product = findProduct();

    return product?.recurringOptions.dateOptions;
  }
});

const everyXDayOptions = computed(() => {
  if (buyMultipleVariant.value) {
    return recurringOptions.value.termOptions;
  } else {
    const product = findProduct();

    return product?.recurringOptions.termOptions;
  }
});

const dayOfWeekJpOptions = computed(() => cartStore.dateOfWeekJp);

const showSubsOrderData = computed(() => {
  if (buyMultipleVariant.value) {
    return paymentScheduleOptions.value.length;
  } else {
    const product = findProduct();

    return product?.recurringOptions.dateOptions || product?.recurringOptions.termOptions;
  }
});

const productId = computed(() => productsData.value?.[0]?.id);

const buyMultipleVariant = computed(() => urlInfo.value.buyMultipleVariant);

const paymentScheduleIsDate = computed(() => paymentSchedule.value == "date");
const paymentScheduleIsTerm = computed(() => paymentSchedule.value == "term");
const paymentScheduleIsDayOfWeek = computed(() => paymentSchedule.value == "day_of_week");

const productChosen: any = ref(null);

watch(
  () => [productId.value, paymentSchedule.value],
  () => {
    changeData();
  },
);

const range = (start: number, stop: number) =>
  Array.from({ length: stop - start + 1 }, (_, i) => start + i);

function onXthDayOptions() {
  const array = range(1, 28).map((item: number) => {
    return {
      text: `${item}日`,
      value: item,
    };
  });

  array.push({ text: `末日`, value: 29 });

  return array;
}

function findProduct() {
  var product;

  switch (props.orderMode) {
    case "upsell":
      product = urlInfo.value.products.find((item) => item.upsellProduct?.id == productId.value);

      product = product ? product.upsellProduct : null;

      break;
    case "cvUpsell":
      product = orderStore.productInfo.cvUpsellProducts.find(
        (item) => item.id == orderStore.cvUpSellVariantData.productSelectedId,
      );

      break;
    case "normal":
      product = urlInfo.value.products.find((item) => item.id == productId.value);

      break;
  }
  return product || null;
}

function changeDefaultSubsOrderData() {
  let product: any = null;
  let productId: any = null;

  if (buyMultipleVariant.value) {
    const productUrlsRecurring = urlInfo.value.productsUrls.filter(
      (item: ProductUrlType) => item.product.isRecurring,
    );

    if (!productUrlsRecurring.length) return;

    if (paymentScheduleOptions.value.length) {
      // when payment schedule options on
      // find the product with the smallest id

      const productIdMin = minBy(productUrlsRecurring, function (productUrl: ProductUrlType) {
        return productUrl.productId;
      });

      productId = productIdMin?.productId;
    } else {
      // when payment schedule options are off
      // find product default in url
      // If the product does not exist, find the product with the largest position

      const productDefaultUrl = productUrlsRecurring.filter(
        (item: ProductUrlType) => item.default,
      )?.[0];

      const productPositionMax = maxBy(productUrlsRecurring, function (productUrl: ProductUrlType) {
        return productUrl.product.position;
      });

      productId = (productDefaultUrl || productPositionMax)?.productId;
    }

    product = urlInfo.value.products.find((item: ProductType) => item.id == productId);
  } else {
    product = findProduct();
  }

  productChosen.value = product;
  defaultData(product || null);
}

function defaultData(product: ProductType | null) {
  if (product && product.isRecurring) {
    if (!paymentSchedule.value) {
      paymentSchedule.value = paymentScheduleOptions.value
        .map((item) => item.value)
        .includes(product.paymentSchedule)
        ? product.paymentSchedule
        : paymentScheduleOptions.value?.[0]?.value || null;
    }

    if (
      !scheduledToBeDeliveredEveryXMonth.value &&
      (paymentScheduleIsDate.value || paymentScheduleIsDayOfWeek.value)
    )
      scheduledToBeDeliveredEveryXMonth.value =
        product.scheduledToBeDeliveredEveryXMonth ||
        product.recurringOptions?.dateOptions?.[0]?.value ||
        recurringOptions.value.dateOptions?.[0]?.value;

    if (!scheduledToBeDeliveredOnXthDay.value && paymentScheduleIsDate.value)
      scheduledToBeDeliveredOnXthDay.value = product.scheduledToBeDeliveredOnXthDay || 1;

    if (!scheduledToBeDeliveredEveryXDay.value && paymentScheduleIsTerm.value) {
      const options = everyXDayOptions.value?.map((item) => Number(item.value));

      scheduledToBeDeliveredEveryXDay.value = options?.includes(
        product.scheduledToBeDeliveredEveryXDay,
      )
        ? product.scheduledToBeDeliveredEveryXDay
        : product.recurringOptions?.termOptions?.[0]?.value ||
          recurringOptions.value.termOptions?.[0]?.value ||
          null;
    }

    if (!scheduledToBeDeliveredEveryXDayOfWeek.value && paymentScheduleIsDayOfWeek.value)
      scheduledToBeDeliveredEveryXDayOfWeek.value =
        product.scheduledToBeDeliveredEveryXDayOfWeek || 1;

    if (!scheduledToBeDeliveredOnXthDayOfWeek.value && paymentScheduleIsDayOfWeek.value)
      scheduledToBeDeliveredOnXthDayOfWeek.value =
        product.scheduledToBeDeliveredOnXthDayOfWeek || 1;
  }
}

function changePaymentSchedule() {
  switch (paymentSchedule.value) {
    case "date":
      scheduledToBeDeliveredEveryXDay.value = null;

      scheduledToBeDeliveredEveryXDayOfWeek.value = null;
      scheduledToBeDeliveredOnXthDayOfWeek.value = null;
      break;
    case "term":
      scheduledToBeDeliveredEveryXMonth.value = null;
      scheduledToBeDeliveredOnXthDay.value = null;

      scheduledToBeDeliveredEveryXDayOfWeek.value = null;
      scheduledToBeDeliveredOnXthDayOfWeek.value = null;
      break;
    case "day_of_week":
      scheduledToBeDeliveredOnXthDay.value = null;

      scheduledToBeDeliveredEveryXDay.value = null;
      break;
  }
}

function clearSubsOrder() {
  subsOrderData.value = {
    paymentSchedule: null,
    scheduledToBeDeliveredEveryXMonth: null,
    scheduledToBeDeliveredOnXthDay: null,
    scheduledToBeDeliveredEveryXDay: null,
    scheduledToBeDeliveredEveryXDayOfWeek: null,
    scheduledToBeDeliveredOnXthDayOfWeek: null,
    scheduledToBeDeliveredAt: null,
  };
}

function changeData() {
  if (!productId.value) {
    if (subsOrderData.value.paymentSchedule) clearSubsOrder();
  } else {
    changePaymentSchedule();
    changeDefaultSubsOrderData();
    if (productChosen.value && !paymentSchedule.value) {
      calculateSubsOrderEstimate(productChosen.value);
    }
  }
}
</script>

<style>
.gap-10px {
  gap: 10px;
}
</style>
