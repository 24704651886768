<template>
  <div class="step-menu bg-white">
    <div class="process">
      <div class="step-link">
        <nav class="w-100 overflow-hidden">
          <ol class="cd-breadcrumb triangle custom-icons row flex-nowrap w-100 mb-0 mt-0">
            <li
              :class="{ current: item.value == step }"
              v-for="(item, index) in stepMapper"
              :key="index"
              class="step-shape col-4"
            >
              <em>
                <p class="h6 m-0 mb-1 text-start">STEP {{ index + 1 }}</p>
                <p class="h3 mb-0 text-start">{{ item.text }}</p></em
              >
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

interface Props {
  step: string;
}

const props = withDefaults(defineProps<Props>(), {
  step: "",
});

const stepMapper = [
  {
    value: "create",
    text: "情報の入力",
  },
  {
    value: "confirm",
    text: "注文確認",
  },
  {
    value: "finish",
    text: "注文完了",
  },
];
const activeIndex = computed(() => stepMapper.findIndex((item: any) => item.value == props.step));
</script>

<style lang="scss" scoped>
@import "@/assets/css/steplist/style.css";

.step-link {
  // flex-grow: 1;

  .h3 {
    font-size: 15px !important;
    color: #000000;
  }

  .h6 {
    font-size: 10px !important;
  }
}
.step-shape {
  .h3,
  .h6 {
    font-weight: 700 !important;
  }
}
.step-shape.current {
  .h3,
  .h6 {
    color: #ffffff;
  }

  background-color: #000a4c;
}
.step-menu {
  margin-bottom: 27px;
  .step-link {
    display: flex;
  }
}

.cd-breadcrumb.triangle li > em {
  font-style: unset !important;
  display: block;
  text-align: center;
  padding: 10px 0px 11px 50px;
  padding-right: 0 !important;
  border-radius: 0 !important;
}
br {
  line-height: 5px !important;
}
.cd-breadcrumb.triangle.custom-icons li > em::after, .cd-breadcrumb.triangle.custom-icons li::after {
  border-top-width: 32px;
  border-bottom-width: 32px;
  border-left-width: 32px;
  top: -5px;
  left: 97%;
}
.cd-breadcrumb.custom-icons li > *::before {
  display: none !important;
}
.cd-breadcrumb.custom-icons li > em::before {
  display: none !important;
}
.cd-breadcrumb.triangle li {
  margin-left: 0 !important;
  border-radius: 0 !important;
}
.cd-breadcrumb.triangle li {
  margin: 0 !important;
}
</style>
