import { PayjpCreditCardType } from "@/types/creditCard";
import { ref } from "vue";
import { Payjp, Element } from "@/types/modules/payjp";
import tokenGmoPgError from "@/helpers/gmo_pg_errors";

const payjp = ref<Payjp | null>(null);

const initPayjp = (creditCard: PayjpCreditCardType, token) => {
  try {
    payjp.value = window.Payjp(token);

    const elements = payjp.value.elements();
    creditCard.cardNumber = elements.create("cardNumber");
    creditCard.cardExpiry = elements.create("cardExpiry");
    creditCard.cardCvc = elements.create("cardCvc");

    creditCard.cardNumber?.mount("#payment_card_number");
    creditCard.cardExpiry?.mount("#payment_card_valid_date");
    creditCard.cardCvc?.mount("#payment_card_cvc");
  } catch (e) {}
};

const linkCreditCard = (creditCard: PayjpCreditCardType, toast: any, onSuccess) => {
  if (!payjp.value || !creditCard.cardNumber) {
    toast.error("Some error happen during processing please try again later...");
    return;
  }

  payjp.value
    .createToken(creditCard.cardNumber, {
      card: { name: creditCard.cardName },
    })
    .then((response) => {
      if (response.error) {
        toast.error(response.error.message!);
      } else onSuccess(response.id);
    });
};

export { linkCreditCard, initPayjp };
