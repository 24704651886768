<template>
  <div>
    <cart-payment-payjp-create v-if="paymentMethodCode == 'payjp'"></cart-payment-payjp-create>
    <div v-if="isAddNewCard">
      <card-gmo-pg-create
        v-if="paymentMethodCode == 'gmo_creditcard_one_time'"
      ></card-gmo-pg-create>

      <card-soft-bank-create
        v-if="paymentMethodCode == 'sbps_creditcard_one_time'"
      ></card-soft-bank-create>

      <card-paygent-create
        v-if="paymentMethodCode == 'paygent_creditcard_one_time'"
      ></card-paygent-create>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useField } from "vee-validate";
import CartPaymentPayjpCreate from "@/components/organisms/pages/cart/cards/CartPaymentPayjpCreate.vue";
import CardGmoPgCreate from "@/components/organisms/pages/cart/cards/CardGmoPgCreate.vue";
import CardSoftBankCreate from "@/components/organisms/pages/cart/cards/CardSoftBankCreate.vue";
import CardPaygentCreate from "@/components/organisms/pages/cart/cards/CardPaygentCreate.vue";

interface Props {
  isAddNewCard: boolean;
}

defineProps<Props>();

const { value: paymentMethodCode } = useField("paymentData.paymentMethodCode");
</script>
