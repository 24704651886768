<template>
  <process-layout title="ご注文完了画面" step="finish">
    <h3 class="process-title pt-5 text-center">ご注文ありがとうございました。</h3>

    <div class="order-number d-flex mt-10">
      <h3 class="process-title mb-0">
        ご注文番号 : <span class="text-danger">{{ props.orderNumber }}</span>
      </h3>
    </div>

    <div class="py-3 base-errors mb-10 mt-5" v-if="errorsHeader.length">
      <div v-for="(error, index) in errorsHeader" :key="index">
        {{ error }}
      </div>
    </div>

    <div class="thanks-page">
      <div>
        <p>ご注文いただきまして、誠にありがとうございます。</p>
        <p>ご登録いただきましたメールアドレス宛に、後ほど注文メールをお送りいたします。</p>
        <p>お手数ですが、ご注文内容にお間違いがないか必ずご確認ください。</p>
      </div>
      <div>
        <p>なお、注文処理状況によっては、メールの送信が遅れる場合がございます。</p>
        <p>
          また、万が一、メールが届かない場合は、迷惑メールフォルダに入っている可能性がありますので、そちらもご確認ください。
        </p>
      </div>
      <div>
        <p>
          何かご不明点やご質問等ございましたら、以下のメールアドレスまでお気軽にお問い合わせくださいませ。
        </p>
        <p>Mail: info@united-cart.jp</p>
      </div>
    </div>

    <div class="text-center">
      <a class="mx-2 back-btn p-0 border-0" :href="`/lp?u=${baseUrl}`"> ＜ トップページへ </a>
    </div>
  </process-layout>
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from "vue";

import { useGlobalStore } from "@/stores/global";
import { useCartStore } from "@/stores/cart";

import ProcessLayout from "@/components/organisms/pages/lp/ProcessLayout.vue";

interface Props {
  orderNumber: String;
  baseUrl: String;
}

const props = defineProps<Props>();

const globalStore = useGlobalStore();
const cartStore = useCartStore();

const errorsHeader = computed(() => globalStore.validationBaseErrors ?? []);

onBeforeMount(() => {
  cartStore.lastPage = "finish";
});
</script>

<style lang="scss" scoped>
.order-number {
  .process-title {
    padding: 15px 38px;
    width: auto;
    border: 2px solid #000a4c;
    display: block;
    height: auto;
    margin: auto;
  }
}

.thanks-page {
  margin-top: 35px;
  h4,
  div {
    margin-bottom: 24px;
    p {
      font-weight: 700;
      font-size: 11px;
      line-height: 21px;
      text-align: center;
      color: #000000;
      margin-bottom: 0;
    }
  }
}
.back-btn {
  background-color: white !important;
  font-weight: 700;
  font-size: 11px;

  &:hover {
    background-color: white;
  }
}
@media screen and (max-width: 700px) {
  .order-number {
    .process-title {
      padding: 15px 0px;
      text-align: center;
      width: 100%;
      border: 2px solid #000a4c;
      display: block;
      height: auto;
      margin: auto;
    }
  }
}
</style>
