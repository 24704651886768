<template>
  <div class="container-xl position-relative" style="max-width: 700px">
    <section v-show="!isLoggedIn">
      <div class="border p-3">
        <b-checkbox
          :checked="showSignInForm"
          :value="true"
          :unchecked-value="false"
          @input="showSignInForm = $event"
          name="login_check"
          id="login_check"
        >
          入力を省略する（当サイトで購入したことがある方）
        </b-checkbox>
      </div>

      <customer-sign-in v-show="showSignInForm" type="cart" />
    </section>

    <div v-show="!showSignInForm && urlInfo">
      <div class="py-3">
        下記お客さま情報を全てご記入いただき、お申し込みボタンをクリックしてください。
      </div>
      <div class="py-3 base-errors" v-if="checkBaseMessage">
        {{ baseMessage }}
      </div>

      <div class="py-3 base-errors" v-if="errorsHeader.length">
        <div v-for="(error, index) in errorsHeader" :key="index">
          {{ error }}
        </div>
      </div>

      <CartProduct
        v-if="formDataReady"
        :products="urlInfo.products"
        :buyMultipleVariant="urlInfo.buyMultipleVariant"
        :productQuantityDisplay="urlInfo.productQuantityDisplay"
        :generalVariantDisplay="urlInfo.generalVariantDisplay"
        :generalAndOwnVariantDisplay="generalAndOwnVariantDisplay"
        :ownVariantDisplay="urlInfo.ownVariantDisplay"
        :productsUrls="urlInfo.productsUrls"
      />

      <CartBillingAddress />

      <CartCustomer
        :confirmEmailDisplay="urlInfo.confirmEmailDisplay"
        :passwordDisplay="urlInfo.passwordDisplay"
        :confirmPasswordDisplay="urlInfo.confirmPasswordDisplay"
      />

      <CartShippingAddress v-if="formDataReady" :setValues="setFormValues" />

      <CartPayment
        v-if="formDataReady"
        :options="urlInfo.availablePaymentMethodOptions"
        :payment-methods="urlInfo.availablePaymentMethods"
      />

      <CartShippingCarrier
        v-if="formDataReady"
        :buyMultipleVariant="urlInfo.buyMultipleVariant"
        :deliveryCompanyDisplay="urlInfo.deliveryCompanyDisplay"
      />

      <specify-delivery-date :no-padding="false" order-mode="normal" />

      <!-- <div class="mt-4 pt-2 mb-3">
        <b-checkbox v-model="optin"> ショップからのお知らせを受け取る </b-checkbox>
      </div> -->

      <form-validator class="mt-4 pt-2 mb-3" float name="termAccepted" clientName="termAccepted">
        <b-checkbox v-model="termAccepted" name="agree_check" id="agree_check">
          <a href="/info/customer_term">利用規約</a>
          に同意して申込みます。未成年者については法定代理人の同意を得ていることを確認します
        </b-checkbox>
      </form-validator>

      <div class="text-center my-5">
        <b-button
          size="lg"
          variant="success"
          type="submit"
          @click="submitForm"
          name="submit"
          id="submit"
        >
          確認ページへ進む
        </b-button>
      </div>

      <validation-status></validation-status>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, onBeforeMount } from "vue";
import { useField } from "vee-validate";
import useCartValidation from "@/composables/useCartValidation";

import CartProduct from "@/components/organisms/pages/cart/CartProduct.vue";
import CartBillingAddress from "@/components/organisms/pages/cart/CartBillingAddress.vue";
import CartShippingCarrier from "@/components/organisms/pages/cart/CartShippingCarrier.vue";
import CartCustomer from "@/components/organisms/pages/cart/CartCustomer.vue";
import CartShippingAddress from "@/components/organisms/pages/cart/CartShippingAddress.vue";
import CartPayment from "@/components/organisms/pages/cart/CartPayment.vue";
import CustomerSignIn from "@/components/organisms/pages/customer/CustomerSignIn.vue";
import SpecifyDeliveryDate from "@/components/organisms/pages/cart/SpecifyDeliveryDate.vue";
import ValidationStatus from "@/components/molecules/common/form/ValidationStatus.vue";
import FormValidator from "@/components/molecules/common/form/FormValidator.vue";

import { CARD_REQUIRED_PAYMENT_METHOD_CODES } from "@/helpers/constants";
import { useCartStore } from "@/stores/cart";
import { useOrderStore } from "@/stores/order";
import { useAuthStore } from "@/stores/auth";
import { useGlobalStore } from "@/stores/global";
import { useBaseInfoStore } from "@/stores/base_info";
import { useValidationStore } from "@/stores/form/validation";

import { cloneDeep } from "lodash";

import { ProductType } from "@/types/ProductType";

interface Props {
  baseUrl: string;
  payjpToken: string;
  confirmPath: string;
  upsellPath: string;
  gmoShopId: string;
  merchantId: string;
  serviceId: string;
  paygentInfo: string;
}

const props = defineProps<Props>();

const globalStore = useGlobalStore();
const orderStore = useOrderStore();
const baseInfoStore = useBaseInfoStore();
const validationStore = useValidationStore();

const baseErrors: any = computed(() => globalStore.validationErrors);

const baseMessage = computed(() => {
  if (baseErrors.value && baseErrors.value.base) {
    return baseErrors.value.base[Object.keys(baseErrors.value.base)[0]][0];
  } else return;
});

const checkBaseMessage = computed(() => {
  if (globalStore.keepError && baseErrors.value.base) {
    return true;
  } else {
    return false;
  }
});

const errorsHeader = computed(() => globalStore.validationBaseErrors ?? []);

const cartStore = useCartStore();

// Fix Prevent safari loading from cache when back button is clicked
window.onpageshow = function (event) {
  if (event.persisted) {
    window.location.reload();
  }
};

const urlData = cartStore.cart.urlData;

const authStore = useAuthStore();

const { isLoggedIn } = authStore;

const urlInfo = computed(() => cartStore.urlInfo);

var {
  setValues: setFormValues,
  resetForm,
  values: formValues,
  setErrors,
  validate,
} = useCartValidation(isLoggedIn, cartStore.cart, urlInfo.value);

const selectedProductId = computed(() => {
  return productsData.value[0].id;
});

const generalAndOwnVariantDisplay = computed(() => {
  return urlInfo.value.generalVariantDisplay && urlInfo.value.ownVariantDisplay;
});

const quantityProductUrlDisplay = computed(() => {
  return generalAndOwnVariantDisplay.value && urlInfo.value.productQuantityDisplay;
});

// Check Product Upsell After Add Array Selected
const productUpsell = computed(() => {
  return (
    addProductUpsell.value && selectedProductId.value == (urlInfo.value.products.at(-1)?.id as any)
  );
});

const isCardRequired = computed(() =>
  CARD_REQUIRED_PAYMENT_METHOD_CODES.includes(paymentMethodCode.value),
);

const formDataReady = ref(false);

const addProductUpsell = ref(false);

const showSignInForm = ref(false);

const fetchUrl = async () => {
  await cartStore.fetchUrl(props.baseUrl);

  const firstProduct = urlInfo.value.products[0];

  if (!firstProduct) return;

  if (urlInfo.value.buyMultipleVariant) {
    cartStore.cart.productsData = urlInfo.value.products.map((item) => {
      return defaultProductData(item);
    });
  } else {
    cartStore.cart.productsData = [defaultProductData(firstProduct)];

    if (urlInfo.value.upsell && firstProduct) {
      if (urlData.upsellBack && urlData.productUpsellId) {
        // Find Product Upsell And Add To Array
        const product = urlInfo.value?.products.find((item) => item.id === urlData.productUpsellId);

        if (product) {
          if (!urlInfo.value?.products.map((item) => item.id).includes(product?.upsellProduct.id)) {
            urlInfo.value.products.push(product.upsellProduct);

            addProductUpsell.value = true;
          }
        }
      }
    }
  }
  if (urlInfo.value.availablePaymentMethodOptions.length && !paymentMethodCode.value) {
    cartStore.cart.paymentData.paymentMethodCode =
      urlInfo.value.availablePaymentMethodOptions[0].value;
  }
};

const fetchCustomer = async () => {
  if (!isLoggedIn) return;

  await cartStore.fetchCustomer();
};

const upsellProductSelected = computed(() => {
  const product = urlInfo.value?.products.find((item) => item.id === selectedProductId.value);

  if (productUpsell.value) {
    return false;
  } else {
    return urlInfo.value?.upsell && !!product?.upsellProduct;
  }
});

const defaultProductData = (product: ProductType) => {
  const productUrl = urlInfo.value.productsUrls.find(
    (productUrl) => productUrl.productId === product.id,
  );

  const quantity = quantityProductUrlDisplay.value ? productUrl?.minQuantity : product.purchaseMin;

  const arrayLength = generalAndOwnVariantDisplay.value ? quantity : 1;

  const variantId = product.variantCombinations ? null : product.variantMasterId;

  const newOptionsValue = [];
  for (let index = 0; index < (arrayLength || 0); index++) {
    newOptionsValue.unshift({
      variantId: variantId,
      quantity: null,
      valueOptions: [
        {
          id: null,
          value: null,
        },
      ],
    });
  }

  return {
    id: product.id,
    quantity: quantity ? quantity : product.purchaseMin,
    variantData: newOptionsValue,
    isRecurring: product.isRecurring,
  };
};

const validateCart = async () => {
  const valid = await cartStore.validateCart();
  const query = `?u=${cartStore.urlInfo.baseUrl}`;

  if (!valid) return;
  else if (upsellProductSelected.value) {
    window.location.href = props.upsellPath + query + "&product_id=" + selectedProductId.value;
  } else {
    window.location.href = props.confirmPath + query;
  }
};

const resetErrors = () => {
  if (!globalStore.keepBaseError) globalStore.resetValidationBaseErrors();

  globalStore.keepBaseError = false;
};

onBeforeMount(() => resetErrors());

onMounted(async () => {
  if (cartStore.resetRequired(props.baseUrl)) {
    cartStore.$reset();
    globalStore.validationErrors = {};
  }

  cartStore.setCartProps(props);

  await baseInfoStore.fetchBaseInfo();
  await fetchCustomer();
  await fetchUrl();

  cartStore.fetchSelectOptions();

  cartStore.orderInfo = null;
  orderStore.createPageViewData(props.baseUrl);
  orderStore.createCustomersAccessHistory(props.baseUrl);

  if (cartStore.resetRequired(props.baseUrl)) resetForm({ values: cartStore.cart });

  formDataReady.value = true;

  validationStore.setErrors = setErrors;

  window.addEventListener("beforeunload", () => (globalStore.keepError = false));
  window.addEventListener("unload", () => (globalStore.keepError = false));

  cartStore.currentBaseUrl = props.baseUrl;
  cartStore.lastPage = "lp";
});

const assignDataAndSubmit = () => {
  cartStore.cart = cloneDeep(formValues);
  validateCart();
};

const submitForm = async () => {
  globalStore.keepError = false;
  // Reset upsell flag to avoid validating wrong product
  if (upsellProductSelected.value) {
    upsellBack.value = false;
  }
  upsellBack.value = productUpsell.value;

  cartStore.currentMode = "normal";

  validate().then(async (result) => {
    validationStore.submitAttempts++;

    if (!result.valid) {
      document.querySelector(".invalid-feedback")?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (!isAddNewCard.value && isCardRequired.value) {
      cardToken.value = "";
      cardTokenKey.value = "";
      assignDataAndSubmit();
      return;
    }

    switch (paymentMethodCode.value) {
      case "cod":
      case "gmo_postpay_seperate_shipment":
      case "np_postpay_wiz":
      case "gmo_postpay_package":
        cardToken.value = "";
        sourceId.value = "";
        cartStore.cart = cloneDeep(formValues);
        assignDataAndSubmit();
        break;
      case "payjp":
      case "gmo_creditcard_one_time":
      case "paygent_creditcard_one_time":
        cartStore.submitWithNewCard((token: string) => {
          cardToken.value = token;
          assignDataAndSubmit();
        });
        break;
      case "sbps_creditcard_one_time":
        cartStore.submitWithNewCard((token: string, tokenKey: string) => {
          cardToken.value = token;
          cardTokenKey.value = tokenKey;
          assignDataAndSubmit();
        });
        break;
      default:
        cardToken.value = "";
        cardTokenKey.value = "";
        sourceId.value = "";
        assignDataAndSubmit();
        break;
    }
  });
};

const { value: paymentMethodCode }: any = useField("paymentData.paymentMethodCode");
const { value: cardToken } = useField("paymentData.cardToken");
const { value: cardTokenKey } = useField("paymentData.cardTokenKey");
const { value: sourceId } = useField("paymentData.sourceId");
const { value: productsData } = useField("productsData");
const { value: upsellBack } = useField("urlData.upsellBack");
const { value: isAddNewCard } = useField("paymentData.isAddNewCard");
const { value: termAccepted } = useField("termAccepted");
</script>

<style lang="scss">
.card {
  .card-header {
    align-items: center;
    min-height: 50px;
    font-weight: 600;
  }
}
</style>
