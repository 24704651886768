<template>
  <div class="validation-modal" ref="validationModal"></div>
  <div v-show="paymentMethodCode != 'payjp' || !isAddNewCard">
    <Transition v-show="showTooltip">
      <div v-if="validationStore.numberOfRemainFields" class="custom-orange-toast">
        <section v-if="!validationStore.numberOfRemainFields">
          <p id="no_required_fields_wrapper">全て入力されています。</p>
        </section>
        <section v-else>
          <p>
            必須項目が
            <span id="total_required_fields">{{ validationStore.numberOfRequiredFields }}</span>
            項目あります。
          </p>
          <p id="remaining_required_fields_wrapper">
            残り
            <span id="remaining_required_fields">{{ validationStore.numberOfRemainFields }}</span>
            項目
          </p>
        </section>
      </div>

      <div class="custom-green-toast" style="display: block" v-else>
        <p>
          必須項目が
          <span>{{ validationStore.numberOfRequiredFields }}</span>
          項目あります。
        </p>
        <p id="no_required_fields_wrapper" style="">全て入力されています。</p>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useValidationStore } from "@/stores/form/validation";
import { useField } from "vee-validate";

const { value: paymentMethodCode } = useField("paymentData.paymentMethodCode");
const { value: isAddNewCard } = useField("paymentData.isAddNewCard");

const validationModal = ref(null);
const showTooltip = ref(false);

const isInViewport = () => {
  if (!validationModal.value) return;
  const rect = validationModal.value.getBoundingClientRect();

  showTooltip.value = rect.top < 0 && rect.bottom >= 0;
};

document.addEventListener("scroll", isInViewport);

onMounted(() => isInViewport());

const validationStore = useValidationStore();
</script>
<style lang="scss" scoped>
.custom-orange-toast {
  z-index: 100;
  max-width: 400px;
  float: right;
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  position: fixed;
  top: 25px;
  right: 25px;
  background: rgba(242, 133, 12, 0.8);
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.custom-green-toast {
  z-index: 100;
  max-width: 400px;
  float: right;
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  position: fixed;
  top: 25px;
  right: 25px;
  background: rgba(4, 162, 34, 0.8);
}
</style>

<style>
.validation-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1000;
}
</style>
