<template>
  <process-layout title="注文確認画面" step="confirm">
    <CartSummary :order-info="orderInfo" confirm-mode="confirm"></CartSummary>

    <div class="text-center mt-5">
      <div class="mb-3">
        <button
          class="process-confirm-btn"
          @click="submit"
          name="submit_confirm"
          id="submit_confirm"
        >
          ご注文完了へ
        </button>
      </div>

      <div class="mb-2">
        <button class="mx-2 process-back-btn p-0" @click="back">入力内容を修正する</button>
      </div>
    </div>
  </process-layout>
</template>

<script setup lang="ts">
import ProcessLayout from "@/components/organisms/pages/lp/ProcessLayout.vue";
import CartSummary from "@/components/organisms/pages/cart/CartSummary.vue";

import { onBeforeMount, ref } from "vue";
import { useCartStore } from "@/stores/cart";
import { useAuthStore } from "@/stores/auth";
import { useOrderStore } from "@/stores/order";
import { useGlobalStore } from "@/stores/global";

interface Props {
  finishPath: string;
  baseUrl: string;
  confirmPath: string;
  cvUpsellPath?: string;
  cvXsellPath?: string;
}

const props = defineProps<Props>();

const cartStore = useCartStore();

const authStore = useAuthStore();
const orderStore = useOrderStore();
const globalStore = useGlobalStore();

const { orderInfo, cart } = cartStore;

const checkSubmit = ref(false);

const upsellNormal = cart.urlData.upsellNormal;

onBeforeMount(() => {
  if (!orderInfo) {
    window.location.href = "/lp?u=" + props.baseUrl;
    return;
  }

  cartStore.lastPage = cartStore.cart.urlData.upsellNormal ? "upsell_back_confirm" : "confirm";

  cartStore.fetchUrl(props.baseUrl);
});

function submit() {
  checkSubmit.value = true;

  cartStore
    .submitCart()
    .then((data) => {
      if (data.token) authStore.setTokenAndCookie(data.token);

      if (!upsellNormal && (data.cvUpsellAvailable || data.cvXsellAvailable)) {
        window.location.href =
          props.cvUpsellPath + window.location.search + "&order_id=" + data.orderNumber;
      } else {
        cartStore.$reset();
        window.location.href =
          props.finishPath + window.location.search + "&order_id=" + data.orderNumber;
      }

      orderStore.resetPageView(props.baseUrl);
    })
    .catch(() => {
      if (globalStore.validationErrors) {
        if (cartStore.currentMode == "upsell") {
          cartStore.cart.productsData = cartStore.preventiveProductsData;
          cartStore.cart.subsOrderData = cartStore.preventiveSubsOrderData;
          cartStore.cart.specifyDeliveryDate = cartStore.preventiveSpecifyDeliveryDate;
          cartStore.cart.urlData.productUpsellId = null;
          cartStore.cart.shippingCarrierId = cartStore.preventiveShippingCarrierId;
        }

        cartStore.cart.urlData.upsellNormal = false;

        globalStore.keepError = true;
        globalStore.keepBaseError = true;

        if (globalStore.resetData) cartStore.$reset();

        window.location.href = "/lp?u=" + props.baseUrl;
      }
    });
}

function back() {
  if (upsellNormal) cart.urlData.upsellBack = true;

  window.location.href = "/lp?u=" + props.baseUrl;
}
</script>
