<template>
  <div>
    <div class="d-grid gap-3">
      <FormValidator label="クレジットカード番号" name="card_number" required>
        <div id="payment_card_number" name="payment_card_number" class="form-control"></div>
      </FormValidator>

      <FormValidator label="カードの名義人" name="card_name" required>
        <b-input
          id="payment_card_name"
          name="payment_card_name"
          v-model="creditCard.cardName"
          placeholder="例）HANAKO YAMADA"
        ></b-input>
      </FormValidator>

      <FormValidator label="カードの有効期限" name="card_expiry" required>
        <div id="payment_card_valid_date" name="payment_card_valid_date" class="form-control"></div>
      </FormValidator>

      <FormValidator label="セキュリティコード" name="card_cvc" required>
        <div id="payment_card_cvc" name="payment_card_cvc" class="form-control"></div>
      </FormValidator>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed, onMounted, watch, inject } from "vue";
import { PayjpCreditCardType, PayjpCreditCardInput } from "@/types/input/customers/CardInput";
import { linkCreditCard, initPayjp } from "@/composables/payment_cards/payjp_card";
import { useCartStore } from "@/stores/cart";
import { Toaster } from "@meforma/vue-toaster";
import { useField } from "vee-validate";

const { value: paymentMethodCode }: any = useField("paymentData.paymentMethodCode");

const cartStore = useCartStore();
const urlInfo = computed(() => cartStore.urlInfo);

let mounted = false;

const toast = inject<Toaster>("toast")!;

const creditCard = reactive<PayjpCreditCardType>(new PayjpCreditCardInput());

const create = (submit: any) => {
  linkCreditCard(creditCard, toast, submit);
};

const handlePayjpInit = () => {
  if (!urlInfo.value.availablePaymentMethodOptions.some((item) => item.value === "payjp")) return;
  else if (!cartStore.cartProps?.payjpToken ?? null) return;
  else if (mounted) return;

  initPayjp(creditCard, cartStore.cartProps.payjpToken);
  mounted = true;
};

watch(() => urlInfo.value.availablePaymentMethodOptions, handlePayjpInit, {
  deep: true,
});

watch(
  paymentMethodCode,
  () => {
    if (paymentMethodCode.value == "payjp") cartStore.submitWithNewCard = create;
  },
  { immediate: true },
);

onMounted(() => {
  handlePayjpInit();
});
</script>
