<template>
  <b-card class="mb-3">
    <template #header>商品</template>
    <div v-for="(product, productIndex) in productsData" :key="productIndex">
      <div class="mb-5 pb-3" :class="checkIsLastElement(productIndex)">
        <b-row gutter-y="3" gutter-x="8">
          <b-col cols="8" sm="9" md="10">
            <div class="merc-select">
              <form-validator
                :name="`product.${productIndex}.id`"
                :clientName="`productsData[${productIndex}].id`"
                float
                required
              >
                <v-select
                  v-if="!buyMultipleVariant"
                  v-model="product.id"
                  class="form-control"
                  label="name"
                  placeholder="選択してください"
                  :clearable="false"
                  :searchable="false"
                  :options="products"
                  :reduce="(item) => item.id"
                  @option:selected="changeDefaultData($event)"
                  name="product_list"
                  id="product_list"
                />
                <label v-else class="mt-4">{{ getProductNameById(product.id) }}</label>
              </form-validator>
            </div>
          </b-col>

          <b-col cols="4" sm="3" md="2" v-if="productsData.length">
            <form-validator :name="`product.${productIndex}.quantity`" required float>
              <b-select
                v-if="productQuantityDisplay"
                v-model="product.quantity"
                class="form-control"
                :options="quantityOptions(productsData[productIndex].id, productIndex)"
                @input="changeQuantity($event, productIndex, product.id)"
                :name="buyMultipleVariant ? `product_qty_${productIndex}` : 'product_qty'"
                :id="buyMultipleVariant ? `product_qty_${productIndex}` : 'product_qty'"
              />
              <label v-else class="mt-4 text-center w-100">
                {{ productsData[productIndex].quantity }}個
              </label>
            </form-validator>
          </b-col>
        </b-row>

        <div v-if="ownVariantDisplay">
          <div v-if="generalVariantDisplay && getData(product.id) && product.id" :key="product.id">
            <b-row
              gutter-x="8"
              offset="1"
              align-h="end"
              class="align-items-center pt-5"
              v-for="(variant, variantIndex) in productsData[productIndex].quantity"
              :key="variantIndex"
            >
              <b-col md="1">
                <b-row> {{ variantIndex + 1 }}個目 </b-row>
              </b-col>
              <b-col md="10">
                <cart-variant-input
                  v-if="product.id"
                  :data="getData(product.id)"
                  :product-quantity-display="productQuantityDisplay"
                  :products-urls="productsUrls"
                  :product-index="productIndex"
                  :variant-index="variantIndex"
                  :component-index="0"
                  :default-attributes="defaultAttributes(productIndex, product.id)"
                ></cart-variant-input>
              </b-col>
            </b-row>
          </div>
        </div>

        <div v-else>
          <div v-show="generalVariantDisplay && getData(product.id)">
            <b-row gutter-y="3" gutter-x="8" offset="1" align-h="end" :key="product.id">
              <b-col md="1">
                <b-row gutter-y="3" class="mt-6"> 1個目 </b-row>
              </b-col>

              <b-col md="7">
                <cart-variant-input
                  :data="getData(product.id)"
                  :product-quantity-display="productQuantityDisplay"
                  :products-urls="productsUrls"
                  :product-index="productIndex"
                  :variant-index="0"
                  :component-index="0"
                  :default-attributes="defaultAttributes(productIndex, product.id)"
                ></cart-variant-input>
              </b-col>

              <b-col md="3">
                <b-row gutter-y="3">
                  <b-col>
                    <label class="mt-4"> {{ productsData[productIndex].quantity }}個 </label>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script setup lang="ts">
import { computed, watch, nextTick, onBeforeMount } from "vue";
import { ProductType } from "@/types/ProductType";
import { ProductUrlType } from "@/types/ProductUrlType";
import { useField } from "vee-validate";
import CartVariantInput from "@/components/organisms/pages/cart/CartVariantInput.vue";
import _, { cloneDeep } from "lodash";
import "vue-select/dist/vue-select.css";

interface Props {
  products: ProductType[];
  productsUrls: ProductUrlType[];
  buyMultipleVariant: boolean;
  productQuantityDisplay: boolean;
  generalVariantDisplay: boolean;
  ownVariantDisplay: boolean;
  generalAndOwnVariantDisplay: boolean;
}

const { value: productsData }: any = useField("productsData");

const props = defineProps<Props>();

const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const quantityOptions = (productId: number | null, productIndex = 0) => {
  let result = [] as any;
  let min = 1;
  let max = 10;

  const product = props.products.find((item) => item.id === productId);
  const productUrl = props.productsUrls.find((item) => item.productId === productId);

  if (productUrl && props.generalAndOwnVariantDisplay) {
    min = productUrl.minQuantity;
    max = productUrl.maxQuantity;
  } else if (product) {
    min = product.purchaseMin;
    max = product.purchaseMax;

    if (changeQuantityOptionCondition(productIndex)) {
      const variant = product.variants.find(
        (item) => item.id == productsData.value[productIndex]?.variantData[0]?.variantId ?? null,
      );

      min = variant?.minQuantity || 1;
      max = variant?.maxQuantity || 10;
    }
  }

  result = range(min, max, 1);

  return result;
};

const getProductNameById = (productId: number | null) => {
  const product = props.products.find((item) => item.id === productId);

  if (product) {
    return product.name;
  }

  return "";
};

const changeDefaultData = (event: any) => {
  const productId = event.id;
  // reset variant id to avoid falling into variant case in quantityOptions function
  if (changeQuantityOptionCondition(0)) {
    productsData.value[0].variantData[0].variantId = null;
  }

  productsData.value[0].quantity = quantityOptions(productsData.value[0].id)[0];
  productsData.value[0].isRecurring = props.products.find(
    (product) => product.id === productId,
  )?.isRecurring;
  changeQuantity(productsData.value[0].quantity, 0, productsData.value[0].id);
};

const changeQuantity = (event: any, index: number, productId: number) => {
  const product = props.products.find((item) => item.id === productId);

  if (product) {
    const arrayLength = props.generalAndOwnVariantDisplay ? event : 1;

    const currentVariantId = productsData.value[index].variantData[0].variantId;

    const newOptionsValue = [];

    if (!props.generalAndOwnVariantDisplay && currentVariantId) {
      const currentVariant = product.variants.find((item) => item.id == currentVariantId);

      if (currentVariant) return;
    }

    const variantId = getData(productId) ? null : product.variantMasterId;

    for (let index = 0; index < arrayLength; index++) {
      newOptionsValue.unshift({
        variantId: variantId,
        quantity: null,
        valueOptions: [
          {
            id: null,
            value: null,
          },
        ],
      });
    }

    productsData.value[index].variantData = newOptionsValue;

    const tmp = cloneDeep(productsData.value[index]);
    productsData.value[index] = { id: null, quantity: 1 };
    nextTick(() => (productsData.value[index] = tmp));
  }
};

function checkIsLastElement(index: number) {
  const products = props.products;
  if (products.length > 1 && products.length - 1 !== index) {
    return "product-item-after";
  }
  return null;
}

const getData = (productId: number | null) => {
  const product = props.products.find((item) => item.id == productId);

  if (product) {
    return product.variantCombinations;
  }

  return {};
};

const changeQuantityOptionCondition = (productIndex: number) => {
  return (
    props.productQuantityDisplay &&
    props.generalVariantDisplay &&
    !props.ownVariantDisplay &&
    productsData?.value[productIndex]?.variantData?.[0]?.variantId
  );
};

const arrayVariantId = computed(() =>
  productsData.value ? productsData.value.map((item: any) => item.variantData?.[0]?.variantId) : [],
);

// ======================================== Set Default Attribute ID And Name ==================
const checkChildDataPresent = (productId: number) => {
  const data = getData(productId) as any;

  if (data && data.options && data.options.length) {
    const child = data.options[0];

    return !!child.optionValueOption;
  }

  return false;
};

const getDefaultIndex = (toIndex: number = 0, fromIndex: number = 0) => {
  let result = 0;

  for (let i = fromIndex; i < toIndex; i++) {
    const data = getData(productsData.value[i].id);

    if (data) result += productsData.value[i].quantity;
  }

  return result;
};

const defaultAttributes = (productIndex: number, productId: number) => {
  const defaultIndex = getDefaultIndex(productIndex);

  const result = {
    defaultIndex: defaultIndex,
    hasChildData: checkChildDataPresent(productId),
  };

  return result;
};
// ===============================================================================================

// ======================================== Add Event Click Jump Product =========================
const addEventClickJumpProduct = () => {
  if (props.buyMultipleVariant || props.generalVariantDisplay || props.ownVariantDisplay) return;

  const els = document.querySelectorAll("a[data-select-id]");

  els.forEach((el) => {
    el.addEventListener("click", () => {
      const variantMasterId = el.getAttribute("data-select-id");
      if (!variantMasterId) return;

      const product = props.products.find(
        (item) => item.variantMasterId.toString() === variantMasterId,
      );

      if (!product) return;

      productsData.value[0].id = product.id;
      changeDefaultData(product);
    });
  });
};

onBeforeMount(() => {
  addEventClickJumpProduct();
});

// ===============================================================================================

// Check array variantId in variant data [0] of ever productsData, if any item changes, will check condition to change quantity options
watch(
  () => arrayVariantId.value,

  (newValue, oldValue) => {
    const dif = _.difference(newValue, oldValue);

    if (dif && dif.length && newValue && newValue.length) {
      const index = newValue.indexOf(dif[0]);

      if (!changeQuantityOptionCondition(index)) return;

      productsData.value[index].quantity = quantityOptions(productsData.value[index].id, index)[0];
    }
  },
);
</script>
<style lang="scss" scoped>
.product-item-after::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #eff2f5;
  margin: 30px auto 0;
}

:deep(.form-control) {
  .merc-select {
    .form-control {
      padding: 0.5rem 1rem;
    }
  }

  .vs__dropdown-toggle {
    padding: 0;
    border: 0;
  }
  .vs__selected-options {
    flex-wrap: nowrap;
    max-width: calc(100% - 41px);
  }

  .v-select {
    padding: 0.75rem 3rem 0.75rem 1rem;
  }

  .vs__selected {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    margin: 0;
  }

  .vs__search {
    position: absolute;
    left: 10px;
  }

  .vs--open .vs__search {
    position: static;
  }

  .vs__dropdown-option {
    display: block;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  .vs__actions {
    padding-left: 10px;
    padding-top: 0;
  }

  #vs1__combobox {
    border: none;
  }

  .vs__selected-options {
    flex-wrap: nowrap;
    max-width: calc(100% - 29px);
  }
  svg {
    width: 10;
  }
}
</style>
