<template>
  <b-row v-show="data" gutter-y="3" class="mt-3 mb-5 justify-content-between align-items-center">
    <b-col cols="4">
      <span class="variant-name" style="font-size: 18px">
        {{ name }}
      </span>
    </b-col>

    <b-col cols="8">
      <form-validator
        :name="`product.0.variantData.0.variantId`"
        :clientName="`variantValues[${componentIndex}].value`"
        float
      >
        <b-select
          v-model="valueId"
          class="form-control"
          :options="options"
          @change="handleSelectVarianValue"
        >
          <template #first>
            <option :value="null">選択してください</option>
          </template>
        </b-select>
      </form-validator>
    </b-col>
  </b-row>

  <cv-upsell-variant-input
    v-if="childData && showChildComponent"
    :data="childData"
    :key-component="keyComponent"
    :product-index="productIndex"
    :variant-index="variantIndex + 1"
    :componentIndex="componentIndex + 1"
  ></cv-upsell-variant-input>
</template>

<script lang="ts">
export default {
  name: "CvUpsellVariantInput",
};
</script>

<script setup lang="ts">
import { computed, ref, nextTick, onMounted } from "vue";
import { useOrderStore } from "@/stores/order";
import { useField } from "vee-validate";

interface Props {
  data: any;
  productIndex: number;
  variantIndex: number;
  componentIndex: number;
  keyComponent: string;
}

const props = defineProps<Props>();

const { value: valueId }: any = useField(`variantValues[${props.componentIndex}].value`);
const showChildComponent = ref(true);

onMounted(() => {
  valueId.value = null;
});

const orderStore = useOrderStore();

const name = computed(() => {
  if (props.data) {
    return props.data.optionTypePresentation;
  }

  return "";
});

const options = computed(() => {
  if (props.data && props.data.options && props.data.options.length) {
    return props.data.options.map((item: any) => {
      return {
        text: item.optionValuePresentation,
        value: item.optionValueId,
      };
    });
  }

  return [];
});

const childData = computed(() => {
  if (props.data && props.data.options && props.data.options.length) {
    const child = props.data.options.find((item: any) => item.optionValueId == valueId.value);

    return child ? child.optionValueOption : null;
  }

  return null;
});

const handleSelectVarianValue = (value: number) => {
  showChildComponent.value = false;
  const options = props.data.options.find((item: any) => item.optionValueId === value);

  if (options && options.variantId) {
    if (props.keyComponent === "upSellVariantData") {
      orderStore.upSellVariantData.variantData[0].variantId = options.variantId;
    } else {
      orderStore.cvUpSellVariantData.variantId = options.variantId;
    }
  }

  nextTick(() => (showChildComponent.value = true));
};
</script>

<style scoped lang="scss">
.variant-name {
  white-space: nowrap;
  font-size: 13px !important;
}
</style>
