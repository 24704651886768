<template>
  <b-row v-if="data" gutter-y="3" gutter-x="3" class="mt-3">
    <b-col md="6"> {{ name }} </b-col>

    <b-col md="6">
      <form-validator>
        <b-select
          v-model="valueId"
          class="form-control"
          :options="options"
          :clientName="`variantValues[${componentIndex}].value`"
          @change="handleSelectVarianValue"
        >
          <template #first>
            <option :value="null">選択してください</option>
          </template>
        </b-select>
      </form-validator>
    </b-col>
  </b-row>

  <cv-xsell-variant-input
    v-if="childData && showChildComponent"
    :data="childData"
    :product-index="productIndex"
    :variant-index="variantIndex + 1"
    :component-index="componentIndex + 1"
  ></cv-xsell-variant-input>
</template>

<script lang="ts">
export default {
  name: "CvXsellVariantInput",
};
</script>

<script setup lang="ts">
import { ref, computed, nextTick } from "vue";
import { useOrderStore } from "@/stores/order";

interface Props {
  data: any;
  productIndex: number;
  variantIndex: number;
  componentIndex: number;
}

const props = defineProps<Props>();

const valueId = ref(null);
const showChildComponent = ref(true);

const orderStore = useOrderStore();

const name = computed(() => {
  if (props.data) {
    return props.data.optionTypePresentation;
  }

  return "";
});

const options = computed(() => {
  if (props.data && props.data.options && props.data.options.length) {
    return props.data.options.map((item: any) => {
      return {
        text: item.optionValuePresentation,
        value: item.optionValueId,
      };
    });
  }

  return [];
});

const childData = computed(() => {
  if (props.data && props.data.options && props.data.options.length) {
    const child = props.data.options.find((item: any) => item.optionValueId == valueId.value);

    return child ? child.optionValueOption : null;
  }

  return null;
});

const handleSelectVarianValue = (value: number) => {
  showChildComponent.value = false;
  const options = props.data.options.find((item: any) => item.optionValueId === value);

  orderStore.productVariantInput[props.productIndex].variantId = options?.variantId || null;

  nextTick(() => (showChildComponent.value = true));
};
</script>
