<template>
  <div v-if="upsell" class="process-container mt-10">
    <h1 class="process-title text-center">これからアップセールです。</h1>

    <b-row gutter-y="3" gutter-x="8" align-h="center" class="upsell-form">
      <b-col>
        <cv-upsell-variant-input
          v-if="variantCombinations"
          key-component="upSellVariantData"
          :data="variantCombinations"
          :product-index="0"
          :variant-index="0"
          :component-index="0"
        ></cv-upsell-variant-input>

        <specify-delivery-date no-padding order-mode="upsell" />
      </b-col>
    </b-row>

    <div class="text-center mt-10">
      <button class="process-confirm-btn" @click="onSubmit">申し込み内容を確認する</button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, watch, onBeforeMount } from "vue";

import { useCartStore } from "@/stores/cart";
import { useOrderStore } from "@/stores/order";
import { useBaseInfoStore } from "@/stores/base_info";

import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { cloneDeep } from "lodash";

import CvUpsellVariantInput from "@/components/organisms/pages/cart/CvUpsellVariantInput.vue";
import SpecifyDeliveryDate from "@/components/organisms/pages/cart/SpecifyDeliveryDate.vue";

import { useValidationStore } from "@/stores/form/validation";

interface Props {
  baseUrl: string;
  upsell: boolean | string;
  confirmPath: string;
  productId: number | string;
}

const props = defineProps<Props>();

const cartStore = useCartStore();
const orderStore = useOrderStore();
const baseInfoStore = useBaseInfoStore();

const urlInfo = computed(() => cartStore.urlInfo);
const validationStore = useValidationStore();

const product = computed(() =>
  cartStore.urlInfo.products.find((item) => item.id == props.productId),
);

const variantId = computed(() =>
  variantCombinations.value ? null : product.value?.upsellProduct.variantMasterId || null,
);

const variantCombinations = computed(() => {
  if (cartStore.urlInfo && cartStore.urlInfo.products) {
    return product.value?.upsellProduct.variantCombinations;
  }

  return null;
});

//////////// Shipping carrier ////////////
const mode = computed(() => baseInfoStore.baseInfo?.shippingCarrierBasedOn ?? "");

const shippingCarrierOptions = computed(() => cartStore.shippingCarrierOptions);

const shippingCarrierVariables = computed(() => ({
  input: {
    paymentMethodCode: cartStore.cart.paymentData.paymentMethodCode,
    productData: [orderStore.upSellVariantData],
  },
}));
//////////// //////////// //////////// ////////////

const { validate } = useForm({
  validationSchema: yup.object().shape({
    variantValues: yup.array().of(
      yup.object({
        value: yup.string().required(),
      }),
    ),
    subsOrderData: yup.object({
      paymentSchedule: yup.string().nullable(),
      scheduledToBeDeliveredEveryXMonth: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => ["date", "day_of_week"].includes(paymentSchedule),
          then: (schema) => schema.required(),
        }),
      scheduledToBeDeliveredOnXthDay: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => paymentSchedule == "date",
          then: (schema) => schema.required(),
        }),
      scheduledToBeDeliveredEveryXDay: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => paymentSchedule == "term",
          then: (schema) => schema.required(),
        }),
      scheduledToBeDeliveredEveryXDayOfWeek: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => paymentSchedule == "day_of_week",
          then: (schema) => schema.required(),
        }),
      scheduledToBeDeliveredOnXthDayOfWeek: yup
        .number()
        .nullable()
        .when(["paymentSchedule"], {
          is: (paymentSchedule: any) => paymentSchedule == "day_of_week",
          then: (schema) => schema.required(),
        }),
    }),
  }),
  initialValues: {
    variantValues: [{ value: variantId.value }],
    productsData: [
      {
        id: null,
        isRecurring: product.value?.upsellProduct.isRecurring,
      },
    ],
    subsOrderData: {
      paymentSchedule: "",
      scheduledToBeDeliveredEveryXMonth: null,
      scheduledToBeDeliveredOnXthDay: null,
      scheduledToBeDeliveredEveryXDay: null,
      scheduledToBeDeliveredEveryXDayOfWeek: null,
      scheduledToBeDeliveredOnXthDayOfWeek: null,
    },
  },
});

const { value: variantValues }: any = useField("variantValues");
const { value: productsData }: any = useField("productsData");
const { value: subsOrderData }: any = useField("subsOrderData");
const { value: specifyDeliveryDate }: any = useField("specifyDeliveryDate");

const { value: shippingAddressData }: any = useField("shippingAddressData");
const { value: billingAddressData }: any = useField("billingAddressData");
const { value: shippingCarrierId }: any = useField("shippingCarrierId");

watch(
  () => variantValues.value.length,
  (newVal, oldVal) => {
    if (newVal > oldVal) {
      variantValues.value.pop();
      variantValues.value.push({ value: null });
    }
  },
);

watch(
  () => urlInfo.value,
  () => fetchUpsellProduct(),
);

watch(shippingCarrierOptions, () => {
  shippingCarrierId.value = shippingCarrierOptions.value?.[0]?.id;
});

watch(
  () => orderStore.upSellVariantData,
  () => {
    if (mode.value == "payment_method") return;

    cartStore.fetchShippingCarrierOptions(shippingCarrierVariables.value);
  },
  { deep: true },
);

function fetchUpsellProduct() {
  const result = {
    id: product.value?.upsellProduct?.id || null,
    quantity: 1,
    isRecurring: product.value?.upsellProduct?.isRecurring || false,
    variantData: [
      {
        variantId: variantId.value,
        quantity: null,
        valueOptions: [
          {
            id: null,
            value: null,
          },
        ],
      },
    ],
  };

  productsData.value[0].id = result.id;
  productsData.value[0].isRecurring = result.isRecurring;
  variantValues.value[0].value = variantId.value;
  orderStore.upSellVariantData = result;
}

const onSubmit = async function () {
  validationStore.submitAttempts++;

  const result = await validate();

  if (result.valid) submitWithUpsell();
};

const submitWithUpsell = async () => {
  cartStore.currentMode = "upsell";

  orderStore.upSellSubsOrderData = cloneDeep(subsOrderData.value);
  orderStore.upSellSpecifyDeliveryDate = cloneDeep(specifyDeliveryDate.value);
  orderStore.upSellShippingCarrierId = cloneDeep(shippingCarrierId.value);

  const valid = await cartStore.validateCart(true);

  const hrefConfirm = props.confirmPath + window.location.search;
  const hrefError = "/lp?u=" + props.baseUrl;

  window.location.href = valid ? hrefConfirm : hrefError;
};

onBeforeMount(() => {
  shippingAddressData.value = {
    sameWithBillingAddress: cartStore.cart.shippingAddressData.sameWithBillingAddress,
    prefectureId: cartStore.cart.shippingAddressData.prefectureId,
  };

  billingAddressData.value = { prefectureId: cartStore.cart.billingAddressData.prefectureId };

  baseInfoStore.fetchBaseInfo();
  shippingCarrierId.value = cartStore.cart.shippingCarrierId;
});
</script>

<style lang="scss">
.upsell-form {
  * {
    font-size: 13px !important;
  }
}
</style>
