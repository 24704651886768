import { createApp } from "vue/dist/vue.esm-bundler.js";
import VueSelect from "vue-select";

import BootstrapVueNext, {
  BFormSelect,
  BFormCheckbox,
  BFormRadio,
  BFormTextarea,
} from "bootstrap-vue-next";

import "@/assets/scss/main.scss";

import Toaster from "@meforma/vue-toaster";

import { localize, setLocale } from "@vee-validate/i18n";

import { configure, defineRule } from "vee-validate";
import ja from "@vee-validate/i18n/dist/locale/ja.json";
import rules from "@vee-validate/rules";

configure({
  generateMessage: localize({ ja }),
});

setLocale("ja");

Object.keys(rules).map((key) => defineRule(key, rules[key]));

import * as yup from "yup";

yup.setLocale({
  mixed: {
    required: "必須項目です",
    notType(ref) {
      switch (ref.type) {
        case "number":
          return "整数を半角で入力してください";
        case "string":
          return "必須項目です";
      }
    },
  },
  string: {
    email: "メールアドレスが正しくありません",
    min(ref) {
      return `${ref.min}文字以上にしてください`;
    },
  },
});

// Global components
import FormValidator from "@/components/molecules/common/form/FormValidator.vue";
import Notice from "@/components/molecules/common/shared/Notice.vue";
import Loading from "@/components/molecules/common/shared/Loading.vue";
import BFormInput from "@/components/molecules/common/shared/BInput.vue";

// Pages
import CartForm from "@/pages/lp/_url/index.vue";
import CartConfirm from "@/pages/lp/_url/confirm.vue";
import CartUpsell from "@/pages/lp/_url/upsell.vue";
import CartCvUpsell from "@/pages/lp/_url/cv_upsell.vue";
import CartCvXsell from "@/pages/lp/_url/cv_xsell.vue";
import CartCvConfirm from "@/pages/lp/_url/cv_confirm.vue";
import CartFinish from "@/pages/lp/_url/finish.vue";
import CartThanksOfferTime from "@/pages/lp/_url/thanks_offer_time.vue";

export const cartFormSetupEntryPoint = () => {
  const app = createApp();
  app.use(BootstrapVueNext);
  app.use(Toaster, { position: "top-right" }).provide("toast", app.config.globalProperties.$toast);

  app.component("CartForm", CartForm);
  app.component("CartConfirm", CartConfirm);
  app.component("CartUpsell", CartUpsell);
  app.component("CartCvUpsell", CartCvUpsell);
  app.component("CartCvXsell", CartCvXsell);
  app.component("CartCvConfirm", CartCvConfirm);
  app.component("CartFinish", CartFinish);
  app.component("CartThanksOfferTime", CartThanksOfferTime);
  app.component("VSelect", VueSelect);

  app.component("FormValidator", FormValidator);
  app.component("Notice", Notice);
  app.component("BFormInput", BFormInput);
  app.component("BInput", BFormInput);
  app.component("BSelect", BFormSelect);
  app.component("BCheckbox", BFormCheckbox);
  app.component("BRadio", BFormRadio);
  app.component("BTextarea", BFormTextarea);
  app.component("Loading", Loading);

  return app;
};
