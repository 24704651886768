<template>
  <b-row v-if="data" class="align-items-center py-2">
    <b-col md="6"> {{ name }} </b-col>
    <b-col md="6">
      <form-validator
        :name="`product.${productIndex}.variantData.${variantIndex}.variantId`"
        :clientName="`productsData[${productIndex}].variantData[${variantIndex}].valueOptions[${componentIndex}].id`"
        float
      >
        <b-select
          v-model="valueId"
          class="form-control"
          :options="options"
          @change="($event: any) => handleSelectVariantValue($event,componentIndex)"
          :id="defaultIdAndNameAttribute"
          :name="defaultIdAndNameAttribute"
        >
          <template #first>
            <option :value="null">選択してください</option>
          </template>
        </b-select>
      </form-validator>
    </b-col>
  </b-row>

  <cart-variant-input
    v-if="childData && showChildComponent"
    :data="childData"
    :product-quantity-display="productQuantityDisplay"
    :products-urls="productsUrls"
    :product-index="productIndex"
    :variant-index="variantIndex"
    :component-index="componentIndex + 1"
    :default-attributes="defaultAttributes"
  ></cart-variant-input>
</template>

<script lang="ts">
export default {
  name: "CartVariantInput",
};
</script>

<script setup lang="ts">
import { ref, computed, watch, nextTick } from "vue";

import { ProductUrlType } from "@/types/ProductUrlType";

import { useCartStore } from "@/stores/cart";

import { useField } from "vee-validate";

interface Props {
  data: any;
  defaultAttributes: any;
  productQuantityDisplay: boolean;
  productIndex: number;
  variantIndex: number;
  componentIndex: number;
  productsUrls: ProductUrlType[];
}

const props = defineProps<Props>();

const cartStore = useCartStore();

const valueId = ref(null);
const showChildComponent = ref(true);

const { value: productsData }: any = useField("productsData");

const defaultIdAndNameAttribute = computed(() => {
  const defaultName = `product_type_${props.defaultAttributes.defaultIndex + props.variantIndex}`;

  return props.defaultAttributes.hasChildData
    ? `${defaultName}_${props.componentIndex}`
    : defaultName;
});

const name = computed(() => {
  if (props.data) {
    return props.data.optionTypePresentation;
  }

  return "";
});

const productQuantity = computed(() => {
  if (productsData.value[props.productIndex]) {
    return productsData.value[props.productIndex].quantity;
  } else {
    return null;
  }
});

const options = computed(() => {
  if (props.data && props.data.options && props.data.options.length) {
    return props.data.options.map((item: any) => {
      return {
        text: item.optionValuePresentation,
        value: item.optionValueId,
      };
    });
  }

  return [];
});

const childData = computed(() => {
  if (props.data && props.data.options && props.data.options.length) {
    const child = props.data.options.find((item: any) => item.optionValueId == valueId.value);
    return child ? child.optionValueOption : null;
  }

  return null;
});

const handleSelectVariantValue = (value: number, optionValueIndex: number) => {
  showChildComponent.value = false;
  const { data, productIndex, variantIndex, componentIndex } = props;
  const variantData = productsData.value[productIndex].variantData[variantIndex];
  const selectedOption = data.options.find((option: any) => option.optionValueId === value);

  if (selectedOption) {
    if (!selectedOption.variantId && !variantData.valueOptions[componentIndex].value) {
      variantData.valueOptions.push({ id: null, value: null });
    } else if (componentIndex < variantData.valueOptions.length - 1) {
      variantData.valueOptions = variantData.valueOptions
        .filter((_, index: number) => index <= componentIndex)
        .concat([{ id: null, value: null }]);
    }

    variantData.variantId = selectedOption.variantId || null;
    variantData.valueOptions[componentIndex] = { value, id: selectedOption.optionValueId };
  } else {
    variantData.variantId = null;
    variantData.valueOptions = variantData.valueOptions
      .slice(0, optionValueIndex)
      .concat([{ id: null, value: null }]);
  }

  nextTick(() => (showChildComponent.value = true));
};

const changeValueIdCondition = computed(() => {
  return (
    props.productQuantityDisplay &&
    cartStore.urlInfo.generalVariantDisplay &&
    !cartStore.urlInfo.ownVariantDisplay &&
    productsData.value?.[props.productIndex]?.variantData?.[0]?.variantId
  );
});

const productIdWatcher = computed(() => productsData.value?.[props.productIndex]?.id ?? undefined);

watch(
  () => productQuantity.value,
  () => {
    if (!changeValueIdCondition.value) valueId.value = null;
  },
);

watch(productIdWatcher, () => {
  valueId.value = null;
});

watch(
  () =>
    productsData.value?.[props.productIndex].variantData?.[props.variantIndex].valueOptions?.[
      props.componentIndex
    ]?.value,
  (newValue, oldValue) => {
    if (newValue && !oldValue) {
      valueId.value = newValue;
    }
  },
  { immediate: true },
);
</script>
