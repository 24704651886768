<template>
  <div>
    <FormValidator
      v-if="sourceIdRequired && !isAddNewCard"
      name="payment_data.source_id"
      client-name="paymentData.sourceId"
      float
    >
    </FormValidator>

    <b-row class="gy-5">
      <b-col cols="12" md="6" v-for="(card, index) in cards" :key="index">
        <CreditCard
          :active="
            sourceId == get(card, cardIdField) || paymentMethodCode == 'sbps_creditcard_one_time'
          "
          :card="card"
          @select="cardSelect"
        ></CreditCard>
      </b-col>

      <b-col cols="12" md="6">
        <div
          @click="$emit('addCard')"
          class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex flex-column flex-center gap-5 h-100"
        >
          <span class="svg-icon svg-icon-3hx svg-icon-primary">
            <AddIcons></AddIcons>
          </span>
          <div class="fs-5 fw-bolder">New Card</div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script setup lang="ts">
import { CARD_REQUIRED_PAYMENT_METHOD_CODES } from "@/helpers/constants";

import { computed, onMounted, watch, ref, nextTick } from "vue";
import { useCartStore } from "@/stores/cart";
import { useAuthStore } from "@/stores/auth";
import { useCardsStore } from "@/stores/customer/cards";
import { useValidationStore } from "@/stores/form/validation";
import { get, forEach } from "lodash";
import { useField } from "vee-validate";

import CreditCard from "@/components/molecules/common/shared/CreditCard.vue";
import AddIcons from "@/components/molecules/common/icons/svg/AddIcon.vue";

type PaymentMethodCode =
  | "payjp"
  | "gmo_creditcard_one_time"
  | "sbps_creditcard_one_time"
  | "paygent_creditcard_one_time";

const { value: paymentMethodCode } = useField<PaymentMethodCode>("paymentData.paymentMethodCode");
const { value: cardToken } = useField("paymentData.cardToken");
const {
  value: sourceId,
  validate: validateSourceId,
  resetField: resetSourceId,
} = useField("paymentData.sourceId");
const { value: isAddNewCard } = useField("paymentData.isAddNewCard");

const cartStore = useCartStore();
const authStore = useAuthStore();
const cardsStore = useCardsStore();
const validationStore = useValidationStore();
const urlInfo = computed(() => cartStore.urlInfo);

const payjpCards = computed(() => cardsStore.payjpCards);

const gmoPgCards = computed(() => cardsStore.gmoPgCards);

const softbankCards = computed(() => cardsStore.softbankCards);

const paygentCards = computed(() => cardsStore.paygentCards);

const showValidation = ref(false);

const cardFetchingMapper = {
  payjp: {
    cards: payjpCards,
    query: cardsStore.fetchPayjpCards,
    idField: "id",
  },
  gmo_creditcard_one_time: {
    cards: gmoPgCards,
    query: cardsStore.fetchGmoPgCards,
    idField: "cardSeq",
  },
  sbps_creditcard_one_time: {
    cards: softbankCards,
    query: cardsStore.fetchSoftbankCards,
    idField: "",
  },
  paygent_creditcard_one_time: {
    cards: paygentCards,
    query: cardsStore.fetchPaygentCards,
    idField: "id",
  },
};

const cards = computed(() => cardFetchingMapper[paymentMethodCode.value]?.cards?.value ?? []);

const cardIdField = computed(() => cardFetchingMapper[paymentMethodCode.value].idField);

const sourceIdRequired = computed(() =>
  CARD_REQUIRED_PAYMENT_METHOD_CODES.includes(paymentMethodCode.value),
);

function handleFetchCustomerCards() {
  if (!authStore.token) return;

  forEach(cardFetchingMapper, (value: any, key: string) => {
    if (urlInfo.value.availablePaymentMethodOptions.some((item) => item.value === key))
      value.query();
  });
}

onMounted(() => {
  handleFetchCustomerCards();
  if (sourceIdRequired.value) showValidation.value = true;
});

const handleSourceId = async () => {
  resetSourceId();

  showValidation.value = false;
  // ============ TO BY PASS VALIDATION AS SOFTBANK ALWAYS HAS ONE CARD ===============
  if (paymentMethodCode.value == "sbps_creditcard_one_time" && cards.value.length)
    sourceId.value = "1";

  validateSourceId().then(() => {
    if (validationStore.setErrors)
      nextTick(() => validationStore.setErrors("paymentData.sourceId"));
  });
};

const cardSelect = (card: any) => {
  if (!cardIdField.value) return;

  sourceId.value = card[cardIdField.value];

  cardToken.value = "";
};

watch(() => urlInfo.value.availablePaymentMethodOptions, handleFetchCustomerCards, {
  deep: true,
});

watch(paymentMethodCode, handleSourceId, { immediate: true });
watch(softbankCards, handleSourceId, { immediate: true });

</script>

<style lang="scss" scoped>
:deep(.payment-method-description) {
  img {
    max-width: 100%;
  }
}
</style>
