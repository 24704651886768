<template>
  <div>
    <card-form :card="creditCard"></card-form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, inject } from "vue";
import { linkCreditCard } from "@/composables/payment_cards/gmo_pg_card";
import { useCartStore } from "@/stores/cart";
import { Toaster } from "@meforma/vue-toaster";
import { useField } from "vee-validate";

import CardForm from "@/components/organisms/pages/cart/cards/CardForm.vue";

const { value: creditCard } = useField("paymentData.card");

const cartStore = useCartStore();

const toast = inject<Toaster>("toast")!;

const create = (submit: any) => {
  linkCreditCard(creditCard.value, cartStore.cartProps.gmoShopId, toast, submit);
};

onMounted(() => {
  cartStore.submitWithNewCard = create;
});
</script>
