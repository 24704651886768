<template>
  <div>
    <card-form :card="creditCard"></card-form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, inject } from "vue";
import { linkCreditCard } from "@/composables/payment_cards/soft_bank_card";
import { useCartStore } from "@/stores/cart";
import { Toaster } from "@meforma/vue-toaster";
import { useField } from "vee-validate";

import CardForm from "@/components/organisms/pages/cart/cards/CardForm.vue";

const cartStore = useCartStore();

const toast = inject<Toaster>("toast")!;

const { value: creditCard } = useField("paymentData.card");

const create = (submit: any) => {
  const setting = {
    merchantId: cartStore.cartProps.merchantId,
    serviceId: cartStore.cartProps.serviceId,
  };

  linkCreditCard(creditCard.value, setting, toast, submit);
};

onMounted(() => {
  cartStore.submitWithNewCard = create;
});
</script>
