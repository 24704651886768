<template>
  <div class="mb-4">
    <h4 class="summary-title">注文内容を確認してください</h4>
    <div v-if="urlInfo.caution02Display" class="caution02">
      <div v-for="productCaution02 in productCautions02" v-html="productCaution02" />
    </div>
    <table class="price-summary" :class="tableClass">
      <thead class="fs-7 fw-bold">
        <tr>
          <th>商品名</th>
          <th>単価</th>
          <th>個数</th>
          <th>小計</th>
        </tr>
      </thead>
      <tbody v-if="orderInfo">
        <tr v-for="(variant, index) in orderInfo.variants" :key="index">
          <td style="white-space: pre-line" class="w-50 text-break">
            {{ variant.name }}
          </td>
          <td>{{ formatPrice(variant.salesPrice) }}</td>
          <td>{{ variant.quantity }} 個</td>
          <td>{{ formatPrice(variant.total) }}</td>
        </tr>
        <tr>
          <td class="text-end fw-bold" colspan="3">小計</td>
          <td>{{ formatPrice(orderInfo.subtotal) }}</td>
        </tr>
        <tr>
          <td class="text-end fw-bold" colspan="3">送料</td>
          <td>{{ formatPrice(orderInfo.delivFee) }}</td>
        </tr>
        <tr>
          <td class="text-end fw-bold" colspan="3">手数料</td>
          <td>{{ formatPrice(orderInfo.charge) }}</td>
        </tr>
        <tr>
          <td class="text-end fw-bold" colspan="3">消費税</td>
          <td>{{ formatPrice(orderInfo.tax) }}</td>
        </tr>
        <tr>
          <td class="text-end fw-bold" colspan="3">合計</td>
          <td class="text-danger fw-700">{{ formatPrice(orderInfo.total) }}</td>
        </tr>
      </tbody>
    </table>

    <div
      v-for="productCaution in productCautions"
      v-html="productCaution"
      v-if="urlInfo.cautionDisplay"
    />
  </div>

  <div class="mb-4">
    <h4 class="summary-title">お客様情報を確認してください</h4>
    <table :class="tableClass">
      <tbody>
        <tr>
          <th width="30%">お名前</th>
          <td>{{ cart.billingAddressData.name01 }} {{ cart.billingAddressData.name02 }}</td>
        </tr>
        <tr>
          <th width="30%">フリガナ</th>
          <td>{{ cart.billingAddressData.kana01 }} {{ cart.billingAddressData.kana02 }}</td>
        </tr>
        <tr>
          <th width="30%">住所</th>
          <td>
            〒{{ cart.billingAddressData.zip01 }}{{ cart.billingAddressData.zip02 }}
            {{ findByCode(cart.billingAddressData.prefectureId)?.name }}
            {{ cart.billingAddressData.addr01 }}
            {{ cart.billingAddressData.addr02 }}
          </td>
        </tr>
        <tr>
          <th width="30%">電話番号</th>
          <td>
            {{ cart.billingAddressData.tel01 }}{{ cart.billingAddressData.tel02
            }}{{ cart.billingAddressData.tel03 }}
          </td>
        </tr>
        <tr>
          <th width="30%">メールアドレス</th>
          <td>
            {{ cart.customerData.email }}
          </td>
        </tr>
        <tr>
          <th width="30%">性別</th>
          <td>
            {{ sexName }}
          </td>
        </tr>
        <tr>
          <th width="30%">生年月日</th>
          <td>
            {{ cart.customerData.birthday }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="mb-4">
    <h4 class="summary-title">お届け先情報を確認してください</h4>
    <table :class="tableClass">
      <tbody>
        <tr>
          <th width="30%">お名前</th>
          <td>{{ shippingAddressData.name01 }} {{ shippingAddressData.name02 }}</td>
        </tr>
        <tr>
          <th width="30%">フリガナ</th>
          <td>{{ shippingAddressData.kana01 }} {{ shippingAddressData.kana02 }}</td>
        </tr>
        <tr>
          <th width="30%">住所</th>
          <td>
            〒{{ shippingAddressData.zip01 }}{{ shippingAddressData.zip02 }}
            {{ findByCode(shippingAddressData.prefectureId)?.name }}
            {{ shippingAddressData.addr01 }}
            {{ shippingAddressData.addr02 }}
          </td>
        </tr>
        <tr>
          <th width="30%">電話番号</th>
          <td>
            {{ shippingAddressData.tel01 }}{{ shippingAddressData.tel02
            }}{{ shippingAddressData.tel03 }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="mb-4">
    <h4 class="summary-title">お支払い方法を確認してください</h4>
    <table :class="tableClass">
      <tbody>
        <tr>
          <th width="30%">決済方法</th>
          <td>{{ paymentMethodName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="mb-4">
    <h4 class="summary-title">配送業者を確認してください</h4>
    <table :class="tableClass">
      <tbody>
        <tr>
          <th width="30%">配送業者</th>
          <td v-if="orderInfo">{{ orderInfo.shippingCarrier.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="mb-4" v-if="showDeliveryDate">
    <h4 class="summary-title">お届け日を確認してください</h4>
    <table :class="tableClass">
      <tbody>
        <tr>
          <th width="30%">１回目お届け日</th>
          <td>
            <div>
              <span>{{ scheduledToBeDeliveredAt || "指定なし" }}</span>
              <span v-if="scheduledDeliveryTime">({{ scheduledDeliveryTime }})</span>
            </div>
          </td>
        </tr>
        <tr>
          <th width="30%">２回目お届け日</th>
          <td>
            <div>
              <span v-if="subsOrderScheduledToBeDeliveredAt">
                {{ subsOrderScheduledToBeDeliveredAt }}
              </span>

              <span v-if="subsOrderScheduledDeliveryTime">
                ({{ subsOrderScheduledDeliveryTime }})
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { useCartStore } from "@/stores/cart";
import { PublicsOrdersPricesType } from "@/types/publics/orders/PricesType";
import { findByCode } from "jp-prefectures";
import { computed, onMounted } from "vue";
import { some } from "lodash";

type PropsType = {
  orderInfo: PublicsOrdersPricesType | null;
  confirmMode: String | null;
};

const props = defineProps<PropsType>();

const cartStore = useCartStore();
const urlInfo = computed(() => cartStore.urlInfo);
const tableClass = "table table-column-bordered gy-4 gx-2 gs-9 mb-6";

const { cart } = cartStore;

const shippingAddressData = computed(() => {
  if (cart.shippingAddressData.sameWithBillingAddress) {
    return cart.billingAddressData;
  }

  if (cart.shippingAddressData.id) {
    const address = cartStore.shippingAddresses.find(
      (item) => item.id === cart.shippingAddressData.id,
    );

    if (address) return address;
  }

  return cart.shippingAddressData;
});

onMounted(() => {
  cartStore.fetchSelectOptions();
});

// const shippingCarrier = computed(() => cartStore.currentShippingCarrier);

const cvConfirmMode = computed(() => props.confirmMode == "cv-confirm");

const subsProductIsInOrder = computed(() =>
  cvConfirmMode.value
    ? cartStore.offerData.isRecurring
    : some(cartStore.cart.productsData, "isRecurring"),
);

const showDeliveryDate = computed(() => {
  if (subsProductIsInOrder.value) {
    return (
      urlInfo.value.subsProductShowScheduledDeliveryDates ||
      urlInfo.value.subsProductAllowScheduleDeliveryTime ||
      urlInfo.value.subsProductAllowScheduleDeliveryDate
    );
  } else {
    return (
      urlInfo.value.normalProductShowScheduledDeliveryDates ||
      urlInfo.value.normalProductAllowScheduleDeliveryTime ||
      urlInfo.value.normalProductAllowScheduleDeliveryDate
    );
  }
});

const scheduledDeliveryTime = computed(() => {
  const specifyDeliveryDate = cvConfirmMode.value
    ? cartStore.offerData.specifyDeliveryDate
    : cartStore.cart.specifyDeliveryDate;

  return specifyDeliveryDate.scheduledDeliveryTime;
});

const productCautions02 = computed(() => {
  return props.orderInfo?.variants.map((p) => p.product.sanitizeCaution02);
});
const productCautions = computed(() => {
  return props.orderInfo?.variants.map((p) => p.product.sanitizeCaution);
});

const scheduledToBeDeliveredAt = computed(() => {
  const specifyDeliveryDate = cvConfirmMode.value
    ? cartStore.offerData.specifyDeliveryDate
    : cartStore.cart.specifyDeliveryDate;

  return specifyDeliveryDate.scheduledToBeDeliveredAt;
});

const subsOrderScheduledDeliveryTime = computed(() => {
  return subsProductIsInOrder.value ? scheduledDeliveryTime.value : null;
});

const subsOrderScheduledToBeDeliveredAt = computed(() => {
  if (!subsProductIsInOrder.value) return null;

  const subsOrderData = cvConfirmMode.value
    ? cartStore.offerData.subsOrderData
    : cartStore.cart.subsOrderData;

  return subsOrderData.scheduledToBeDeliveredAt;
});

const paymentMethodName = computed(() => {
  return cartStore.urlInfo.availablePaymentMethodOptions.find(
    (item) => item.value === cart.paymentData.paymentMethodCode,
  )?.text;
});

const sexName = computed(() => {
  return cartStore.sexes.find((item: any) => item.value == cartStore.cart.customerData.sexId)?.text;
});

function formatPrice(value: number) {
  if (!value) return "0円";

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "JPY",
    currencyDisplay: "code",
  });
  const tmp = formatter.format(value).replace("JPY", "").trim();

  return `${tmp}円`;
}
</script>

<style lang="scss" scoped>
.summary-title {
  font-family: Inter, sans-serif;
  font-size: 1em;
  line-height: 1.4em;
  padding: 1.8% 2.4%;
  font-weight: 700;
  color: #fff;
  background-color: #000a4c;
  margin-bottom: 1%;
}
.table {
  th,
  td {
    padding: 5px 4px !important;
  }
}
.price-summary {
  td:last-child,
  th:last-child {
    text-align: right !important;
    width: 50px;
  }
}
.caution02 {
  border: solid 1px #ddd;
  border-bottom: solid 3px #000a4c;
  padding: 5px 4px;
}
</style>
