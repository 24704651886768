<template>
  <b-card class="mb-3 bg-white" v-show="displaySection" :class="{ 'no-padding': noPadding }">
    <template #header>お届け日の指定</template>

    <subs-order class="mb-10" :order-mode="props.orderMode" v-if="props.orderMode != `xsell`" />
    <cv-xsell-subs-order v-else />

    <form-validator
      v-if="urlInfo && allowScheduleDeliveryDate"
      name="specify_delivery_date.scheduled_to_be_delivered_at"
      label="お届け日の指定"
      class="mb-4 col-6"
    >
      <b-select
        :options="deliveryDateAtOptions"
        v-model="scheduledToBeDeliveredAt"
        name="shipping_date"
        id="shipping_date"
      >
        <template #first v-if="allowNilInDeliveryDate">
          <option :value="null">指定なし</option>
        </template></b-select
      >
    </form-validator>

    <form-validator label="お届け時間の指定" v-if="allowScheduleDeliveryTime">
      <div class="d-flex align-items-baseline">
        <form-validator class="w-50" name="specify_delivery_date.scheduled_delivery_time">
          <b-select
            :options="deliveryTimesOptions"
            v-model="scheduledDeliveryTime"
            name="shipping_time"
            id="shipping_time"
          >
            <template #first>
              <option value="">指定なし</option>
            </template>
          </b-select>
        </form-validator>
        <span class="ms-2 px-2">に配達</span>
      </div>
    </form-validator>

    <div class="d-flex mt-4" v-if="showScheduledDeliveryDates">
      <div v-if="subsProductIsInOrder">
        <span class="me-2">1回目お届け日:</span>
        <span v-if="!scheduledToBeDeliveredAt">指定なし</span>
        <span v-else>
          {{ scheduledToBeDeliveredAt }}
        </span>
        <span class="ms-2" v-if="scheduledDeliveryTime"> ({{ scheduledDeliveryTime }}) </span>
        <br />
        <span class="me-2">2回目お届け日</span>
        <span v-if="subsOrderScheduledToBeDeliveredAt">
          {{ subsOrderScheduledToBeDeliveredAt }}
        </span>
        <span class="ms-2" v-if="scheduledDeliveryTime"> ({{ scheduledDeliveryTime }}) </span>
      </div>
      <div v-else>
        <span class="me-2">お届け日:</span>
        <span v-if="!scheduledToBeDeliveredAt">指定なし</span>
        <span v-else>
          {{ scheduledToBeDeliveredAt }}
        </span>
        <span class="ms-2" v-if="scheduledDeliveryTime"> ({{ scheduledDeliveryTime }}) </span>
      </div>
    </div>
  </b-card>
</template>

<script lang="ts" setup>
import { onMounted, computed, watch, ref, withDefaults } from "vue";
import { useCartStore } from "@/stores/cart";
import { useOrderStore } from "@/stores/order";

import { useField } from "vee-validate";

import { some } from "lodash";

import SubsOrder from "@/components/organisms/pages/cart/SubsOrder.vue";
import CvXsellSubsOrder from "@/components/organisms/pages/cart/CvXsellSubsOrder.vue";

interface Props {
  orderMode: string;
  noPadding: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  orderMode: "",
  noPadding: false,
});

withDefaults;

const cartStore = useCartStore();
const orderStore = useOrderStore();

const urlInfo = computed(() => cartStore.urlInfo);

const { value: scheduledDeliveryTime }: any = useField("specifyDeliveryDate.scheduledDeliveryTime");
const { value: scheduledToBeDeliveredAt }: any = useField(
  "specifyDeliveryDate.scheduledToBeDeliveredAt",
);
const { value: shippingCarrierId }: any = useField("shippingCarrierId");
const { value: billingPrefectureId }: any = useField("billingAddressData.prefectureId");
const { value: shippingPrefectureId }: any = useField("shippingAddressData.prefectureId");
const { value: sameWithBillingAddress }: any = useField(
  "shippingAddressData.sameWithBillingAddress",
);

const { value: productsData }: any = useField("productsData");
const { value: subsOrderScheduledToBeDeliveredAt }: any = useField(
  "subsOrderData.scheduledToBeDeliveredAt",
);

const deliveryTimesOptions = computed(() => cartStore.deliveryTimesOptions);
const deliveryDateAtOptions = computed(() => cartStore.deliveryDateAtOptions);

const allowScheduleDeliveryDate = ref(true);
const allowScheduleDeliveryTime = ref(true);
const showScheduledDeliveryDates = ref(true);
const allowNilInDeliveryDate = ref(true);
const subsProductIsInOrder = ref(true);

const watchBackDeliveryTimeFirstTime = ref(true);
const watchBackDeliveredAtFirstTime = ref(true);

watch(shippingCarrierId, handleDeliveryTimeOptions);

watch(
  productsData,
  () => {
    subsProductIsInOrder.value = some(productsData.value, "isRecurring");
    allowScheduleDeliveryDate.value = subsProductIsInOrder.value
      ? urlInfo.value.subsProductAllowScheduleDeliveryDate
      : urlInfo.value.normalProductAllowScheduleDeliveryDate;
    allowScheduleDeliveryTime.value = subsProductIsInOrder.value
      ? urlInfo.value.subsProductAllowScheduleDeliveryTime
      : urlInfo.value.normalProductAllowScheduleDeliveryTime;
    showScheduledDeliveryDates.value = subsProductIsInOrder.value
      ? urlInfo.value.subsProductShowScheduledDeliveryDates
      : urlInfo.value.normalProductShowScheduledDeliveryDates;
    allowNilInDeliveryDate.value = subsProductIsInOrder.value
      ? urlInfo.value.subsProductAllowNilInDeliveryDate
      : urlInfo.value.normalProductAllowNilInDeliveryDate;
  },
  {
    deep: true,
    immediate: true,
  },
);

watch(
  () => [billingPrefectureId.value, shippingPrefectureId.value, sameWithBillingAddress.value],
  () => {
    handleDeliveryDateAtOptions();
  },
);

watch(deliveryTimesOptions, () => {
  if (watchBackDeliveryTimeFirstTime.value) {
    watchBackDeliveryTimeFirstTime.value = false;

    if (scheduledDeliveryTime.value) return;
  }

  scheduledDeliveryTime.value = "";
});

watch(
  () => [deliveryDateAtOptions.value, allowNilInDeliveryDate.value],
  () => {
    if (watchBackDeliveredAtFirstTime.value) {
      watchBackDeliveredAtFirstTime.value = false;

      if (scheduledToBeDeliveredAt.value) return;
    }

    scheduledToBeDeliveredAt.value = allowNilInDeliveryDate.value
      ? null
      : deliveryDateAtOptions.value[0]?.value;
  },
);

function handleDeliveryTimeOptions() {
  if (shippingCarrierId.value) {
    cartStore.deliveryTimes(shippingCarrierId.value);
  } else {
    cartStore.deliveryTimesOptions = [];
  }
}

function handleDeliveryDateAtOptions() {
  const prefectureId = sameWithBillingAddress.value
    ? billingPrefectureId.value
    : shippingPrefectureId.value;

  if (prefectureId) {
    cartStore.orderSetting(prefectureId);
  } else {
    cartStore.deliveryDateAtOptions = [];
  }
}

// Check Show Sub Order (start)
function findProduct() {
  var product;

  switch (props.orderMode) {
    case "upsell":
      product = urlInfo.value.products.find((item) => item.upsellProduct?.id == productId.value);

      product = product ? product.upsellProduct : null;

      break;
    case "cvUpsell":
      product = orderStore.productInfo.cvUpsellProducts.find(
        (item) => item.id == orderStore.cvUpSellVariantData.productSelectedId,
      );

      break;
    case "normal":
      product = urlInfo.value.products.find((item) => item.id == productId.value);

      break;
  }
  return product || null;
}

const recurringOptions = computed(() => urlInfo.value.recurringOptions ?? {});
const paymentScheduleOptions = computed(() => recurringOptions.value.paymentScheduleOptions ?? []);
const productId = computed(() => productsData.value?.[0]?.id);

const showSubsOrderData = computed(() => {
  if (urlInfo.value.buyMultipleVariant) {
    return paymentScheduleOptions.value.length;
  } else {
    const product = findProduct();

    return product?.recurringOptions.dateOptions || product?.recurringOptions.termOptions;
  }
});
// Check Show Sub Order (end)

onMounted(() => {
  handleDeliveryDateAtOptions();
  handleDeliveryTimeOptions();
});

const displaySection = computed(() => {
  const displayCondition =
    allowScheduleDeliveryDate.value ||
    allowScheduleDeliveryTime.value ||
    showScheduledDeliveryDates.value;

  return props.orderMode == "xsell"
    ? productsData.value?.length && displayCondition
    : displayCondition || showSubsOrderData.value;
});
</script>

<style lang="scss">
.no-padding {
  padding: 0 !important;

  .card-header,
  .card-body {
    padding: 0.75rem !important;
    border: 0 !important;
  }
}
</style>
