<template>
  <b-card class="mb-3">
    <template #header>お客様情報</template>

    <div class="d-grid gap-5">
      <FormValidator
        label="メールアドレス"
        name="customer_data.email"
        clientName="customerData.email"
        required
        float
      >
        <b-input
          v-model="email"
          placeholder="例）○○○@example.com"
          type="email"
          :disabled="authStore.isLoggedIn"
          name="order_email"
          id="order_email"
        ></b-input>
      </FormValidator>

      <FormValidator
        label="メールアドレス（確認）"
        name="customer_data.email_confirmation"
        clientName="customerData.emailConfirmation"
        v-if="confirmEmailDisplay"
        required
        float
      >
        <b-input
          v-model="emailConfirmation"
          placeholder="例）○○○@example.com"
          type="email"
          :disabled="authStore.isLoggedIn"
          name="order_email_confirm"
          id="order_email_confirm"
        ></b-input>
        <p class="description">
          メールアドレスに誤りがありますとメールが届きません。今一度ご確認ください。
        </p>
      </FormValidator>

      <template v-if="!authStore.isLoggedIn">
        <FormValidator
          v-if="displayPasswordCondition"
          label="パスワード"
          name="customer_data.password"
          clientName="customerData.password"
          required
          float
        >
          <b-input
            v-model="password"
            placeholder="半角英数字8文字以上（大文字小文字をそれぞれ含む）"
            type="password"
            autocomplete="new-password"
            name="order_password"
            id="order_password"
          ></b-input>
        </FormValidator>

        <FormValidator
          v-if="confirmPasswordDisplay"
          label="パスワード（確認）"
          name="customer_data.password_confirmation"
          clientName="customerData.passwordConfirmation"
          required
          float
        >
          <b-input
            v-model="passwordConfirmation"
            placeholder="半角英数字8文字以上（大文字小文字をそれぞれ含む）"
            type="password"
            name="order_password_confirm"
            id="order_password_confirm"
          ></b-input>
        </FormValidator>
      </template>

      <FormValidator
        label="性別"
        name="customer_data.sex_id"
        clientName="customerData.sexId"
        required
        float
      >
        <b-select v-model="sexId" :options="cartStore.sexes" name="order_sex" id="order_sex">
          <template #first>
            <option :value="null">選択してください</option>
          </template>
        </b-select>
      </FormValidator>

      <FormValidator
        label="生年月日"
        name="customer_data.birthday"
        clientName="customerData.birthday"
        required
        float
      >
        <b-input v-model="birthday" type="date" name="order_birthday" id="order_birthday"></b-input>
      </FormValidator>
    </div>
  </b-card>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/stores/auth";
import { useCartStore } from "@/stores/cart";
import { useField } from "vee-validate";

import { computed, onMounted } from "vue";

interface Props {
  confirmEmailDisplay: boolean;
  passwordDisplay: boolean;
  confirmPasswordDisplay: boolean;
}

const props = defineProps<Props>();

const authStore = useAuthStore();
const cartStore = useCartStore();

const { value: email } = useField("customerData.email");
const { value: emailConfirmation } = useField("customerData.emailConfirmation");
const { value: password } = useField("customerData.password");
const { value: passwordConfirmation } = useField("customerData.passwordConfirmation");
const { value: sexId } = useField("customerData.sexId");
const { value: birthday } = useField("customerData.birthday");

const displayPasswordCondition = computed(
  () => props.passwordDisplay || (!props.passwordDisplay && props.confirmPasswordDisplay),
);

onMounted(() => {
  password.value = "";
  passwordConfirmation.value = "";
  cartStore.cart.customerData.password = "";
  cartStore.cart.customerData.passwordConfirmation = "";
});
</script>

<style scoped>
.description {
  padding-top: 12px;
  font-size: 12px;
}
</style>
