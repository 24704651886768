<template>
  <b-card class="mb-3">
    <template #header>お届け先情報</template>

    <div class="d-grid gap-3">
      <FormValidator label="お届け先住所" required>
        <b-select
          v-model="shippingAddress"
          :options="options"
          :disabled="disableShippingAddressOptions"
          name="shipping_address_select"
          id="shipping_address_select"
        ></b-select>
      </FormValidator>
      <div v-if="shippingAddress == 'new'">
        <FormAddress
          :addressData="shippingAddressData"
          prefix="shippingAddressData"
          prefixNameValidator="shipping_address_data"
          :isShipping="true"
        ></FormAddress>
      </div>
    </div>
  </b-card>
</template>

<script setup lang="ts">
import { useCartStore } from "@/stores/cart";
import { computed, ref, watch, onMounted } from "vue";
import { useField } from "vee-validate";
import FormAddress from "@/components/molecules/pages/cart/FormAddress.vue";

const cartStore = useCartStore();
const { value: paymentMethodCode } = useField("paymentData.paymentMethodCode");

const options = computed(() => {
  return [
    {
      text: "上記住所と同じ",
      value: "same",
    },
    {
      text: "新しく入力する",
      value: "new",
    },
  ].concat(
    cartStore.shippingAddresses.map((item) => ({
      text: item.fullAddress,
      value: item.id,
    })) as any[],
  );
});

const { value: id } = useField("shippingAddressData.id");
const { value: sameWithBillingAddress } = useField("shippingAddressData.sameWithBillingAddress");
const { value: shippingPrefectureId } = useField("shippingAddressData.prefectureId");
const { value: shippingAddressData } = useField("shippingAddressData");
const disableShippingAddressOptions = ref(false);

const shippingAddress = computed({
  get() {
    if (sameWithBillingAddress.value) {
      return "same";
    } else if (!id.value) {
      return "new";
    }

    return id.value;
  },

  set(value) {
    switch (value) {
      case "same":
        id.value = undefined;
        sameWithBillingAddress.value = true;
        break;
      case "new":
        id.value = undefined;
        shippingPrefectureId.value = "";
        sameWithBillingAddress.value = false;
        break;
      default:
        id.value = value;
        shippingPrefectureId.value = cartStore.shippingAddresses.find(
          (address) => address.id === value,
        )?.prefectureId;
        sameWithBillingAddress.value = false;
        break;
    }
  },
});

onMounted(() => {
  handleShippingAddress();
});

watch(paymentMethodCode, () => {
  handleShippingAddress();
});

function handleShippingAddress() {
  if (paymentMethodCode.value === "gmo_postpay_package") {
    shippingAddress.value = "same";
    disableShippingAddressOptions.value = true;
  } else {
    disableShippingAddressOptions.value = false;
  }
}
</script>
