<template>
  <b-card class="mb-3">
    <template #header>お客様情報のご入力</template>
    <FormAddress
      :addressData="billingAddressData"
      prefix="billingAddressData"
      prefixNameValidator="billing_address_data"
    ></FormAddress>
  </b-card>
</template>

<script setup lang="ts">
import { useField } from "vee-validate";
import FormAddress from "@/components/molecules/pages/cart/FormAddress.vue";

const { value: billingAddressData } = useField("billingAddressData");
</script>
