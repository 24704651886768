<template>
  <div v-if="show" class="process-container my-10">
    <div class="text-center">
      <template v-if="timeout > 0">
        <h4><u>このページを閉じないでください。</u></h4>
        <h2>
          <span v-if="remaining.hours">{{ remaining.hours }} 時間</span>
          <span v-if="remaining.minutes">{{ remaining.minutes }} 分</span>
          <span v-if="!remaining.minutes && remaining.hours">00 分</span>
          {{ remaining.seconds }} 秒
        </h2>
        <p>このページの有効期間のみ、下記の商品を購入できます。</p>
      </template>
      <template v-else>
        <p>有効期限を経過しました。</p>
        <p>受付を終了させていただきます。</p>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onBeforeMount } from "vue";

import { useOrderStore } from "@/stores/order";
import { useCartStore } from "@/stores/cart";
import { useBaseInfoStore } from "@/stores/base_info";

interface Props {
  orderNumber: string;
  baseUrl: string;
}

const props = defineProps<Props>();

const orderStore = useOrderStore();
const cartStore = useCartStore();
const baseInfoStore = useBaseInfoStore();

const timeout = ref(0);

const cvUpsellProducts = computed(() => orderStore.productInfo.cvUpsellProducts);
const cvXsellProducts = computed(() => orderStore.productInfo.cvXsellProducts);

const show = computed(() => {
  return cvUpsellProducts.value.length || cvXsellProducts.value.length;
});

const interval = ref<any>(null);

const BASE_COUNTDOWN = (new Date().getTimezoneOffset() / 60 + 9) * 3600;

const remaining = computed(() => ({
  hours: Math.floor(timeout.value / 3600),
  minutes: Math.floor(timeout.value / 60) % 60,
  seconds: timeout.value % 60,
}));

orderStore
  .fetchOffer(props.orderNumber)
  .then(() => {
    timeout.value = BASE_COUNTDOWN + orderStore.productInfo.timeout;

    interval.value = setInterval(() => {
      timeout.value -= 1;
      if (timeout.value <= 0) clearInterval(interval.value);
    }, 1000);
  })
  .catch(() => {
    window.location.href = "/lp?u=" + props.baseUrl;
  });

onBeforeMount(() => {
  cartStore.fetchSelectOptions();
  baseInfoStore.fetchBaseInfo();

  cartStore.lastPage = "thank_offer";
});
</script>
